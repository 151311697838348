import { useState, useEffect, useRef } from "react";
import { resources } from '../helpers/Resource';
import api_url, { saveVideoLessonApi, getVideoLessonsApi, deleteVideoLessonApi } from '../util/api';
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage";
import YouTube from 'react-youtube';

const VideoLesson = () => {
    const [videoName, setVideoName] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const filesInput = useRef(null);
    const posterInput = useRef(null);

    const opts = {
        height: '300',
        width: '300',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,  // This will auto-play the video when loaded
        },
    };

    useEffect(() => {
        getVideoLessons();
    }, []);

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo(); // example: pause the video when ready
    }

    const getVideoLessons = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const response = await getVideoLessonsApi(null);
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.errorMessage} />
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            // formData.append('file', filesInput.current.files[0]);
            // formData.append('poster', posterInput.current.files[0]);
            formData.append('videoUrl', videoUrl);
            formData.append('videoName', videoName);
            const response = await saveVideoLessonApi(formData);
            if (response.status === 200) {
                setSuccess(true);
                setError(false);
                setData(response.data);
                // filesInput.value = null;
                // posterInput.value = null;
                setVideoName('');
                setVideoUrl('');
                setLoading(false);
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const videoNameChange = (event) => {
        setVideoName(event.target.value);
    }

    const videoUrlChange = (event) => {
        setVideoUrl(event.target.value);
    }

    const deleteLesson = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            const response = await deleteVideoLessonApi(formData);
            if (response.status === 200) {
                setError(false);
                setSuccess(true);
                setData(response.data);
                setLoading(false);
            }
            else {
                setLoading(false);
                setError(true);
                setData([]);
                setSuccess(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div style={{ margin: 20 }}>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4>{resources.videoLessonPageTitle}</h4>
            </div>

            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.videoLessonPageSuccessMessage} />
                    : null
            }

            <div className="shadow p-3 mb-5 bg-white rounded">
                <div style={{ margin: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.videoLessonPageVideoName}</label>
                                        <input type="text" className="form-control" id="createLessonLessonTitle" value={videoName} onChange={videoNameChange} />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text">Upload</label>
                                        <input type="file" accept="video/mp4,video/x-m4v,video/*" className="form-control" id="createVideoLesson" ref={filesInput} />
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.videoLessonPageVideoURL}</label>
                                        <input type="text" className="form-control" id="createLessonLessonUrl" value={videoUrl} onChange={videoUrlChange} />
                                    </div>
                                </div>
                            </div>

                            {/* 
                            <div className="row">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text">Upload Poster</label>
                                        <input type="file" accept="image/*" className="form-control" id="poster" ref={posterInput} />
                                    </div>
                                </div>
                            </div> */}


                            <div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-primary btn-block" type="submit" disabled={loading || videoName == ''}>{loading ? resources.loading : resources.save}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {
                data.length > 0
                    ?
                    <div>
                        <h4>{resources.videoLessonVideos}</h4>
                        <div className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>Count per page:</span>
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>{resources.videoLessonPageThCreatedDate}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.videoLessonPageThVideoName}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.videoLessonPageThAssignedFullName}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.videoLessonPageThVideo}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.videoLessonPageThDelete}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.id}>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.createdDateString}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.videoName}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.assignedFullName}</td>
                                                {/* <td>{item.videoPath}</td> */}
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }} key={`video_${item.id}`}>
                                                    {/* <video width="500" height='300' controls>
                                                        <source src={item.videoPath} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video> */}
                                                    {/* <a href={item.videoPath} target="_blank">
                                                        <span>video</span>
                                                    </a> */}

                                                    <YouTube videoId={item.videoPath} opts={opts} onReady={onPlayerReady} />

                                                    {/* <video width="200" height="200" controls>
                                                        <source src={`https://www.youtube.com/watch?v=${item.videoPath}`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video> */}
                                                </td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteLesson(item.id)}>{resources.delete}</button>
                                                </td>
                                                {/* <td><i key={`td_${item.id}`} className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(item.workoutPDFPath)}></i></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                    Previous
                                </button>
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                    Next
                                </button>
                                <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                            </div>
                        </div>
                    </div>
                    : null
            }

        </div>
    )
}

export default VideoLesson;
