

import { useState, useEffect, useRef } from "react";
import { resources } from '../helpers/Resource';
import api_url, { saveVideoLessonApi, getVideoLessonsApi, updateUserTakenLessonApi } from '../util/api';
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

const UserTakenLesson = () => {

    const [lessonCount, setLessonCount] = useState(0);
    const [lessonCountDb, setLessonCountDb] = useState(0);
    const [remainingLessonCount, setRemainingLessonCount] = useState(0);
    const [remainingLessonCountDb, setRemainingLessonCountDb] = useState(0);
    const [takenDate, setTakenDate] = useState('');
    const [dateList, setDateList] = useState([]);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserTakenLesson();
    }, []);

    const getUserTakenLesson = async () => {
        setError(false);
        setLoading(true);
        setSuccess(false);

        try {
            // const formData = new FormData();
            // formData.append('userID', id);
            // const response = await getUserTakenLessonApi(formData);
            // if (response.status === 200) {
            //     setLoading(false);
            //     setError(false);
            //     setLessonCountDb(response.data.lessoncount);
            //     setRemainingLessonCountDb(response.data.remaininglessoncount);
            // }
            // else {
            //     setLoading(false);
            //     setError(true);
            // }

            setLessonCountDb(0);
            setRemainingLessonCountDb(0);
            setRemainingLessonCount(0);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }




    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            // const formData = new FormData();
            // formData.append('lessoncount', lessonCount);
            // formData.append('remaininglessoncount', remainingLessonCount);
            // const response = await updateUserTakenLessonApi(formData);
            // if (response.status === 200) {
            //     setSuccess(true);
            //     setError(false);
            //     setLoading(false);
            //     setLessonCountDb(lessonCount);
            // }
            // else {
            //     setLoading(false);
            //     setSuccess(false);
            //     setError(true);
            // }

            setSuccess(true);
            setLoading(false);
            setLessonCountDb(10);
            setRemainingLessonCount(10);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }


    const lessonCountChange = (event) => {
        setLessonCount(event.target.value);
    }

    const takenDateChange = (event) => {
        setTakenDate(event.target.value);
    }

    const remainingLessonCountChange = (event) => {
        setRemainingLessonCount(event.target.value);
    }

    const renderCreateUserTakenLesson = () => {
        return (
            <div style={{ margin: 20 }}>

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <h4>{resources.usertakenPageTitle}</h4>
                </div>

                {
                    !loading && success
                        ? <SuccessMessage successMessage={resources.usertakenPageSuccessMessage} />
                        : null
                }

                <div className="shadow p-3 mb-5 bg-white rounded">
                    <div style={{ margin: 20 }}>
                        <form onSubmit={handleSubmit}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3">
                                            <label className="form-label">{resources.usertakenPageLessonTitle}</label>
                                            <input type="number" className="form-control" value={lessonCount} onChange={lessonCountChange} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.save}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const handleSubmitTakenLesson = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        if (takenDate == null || takenDate == '') {
            return;
        }   

        try {
            // const formData = new FormData();
            // formData.append('lessoncount', lessonCount);
            // formData.append('remaininglessoncount', remainingLessonCount);
            // const response = await updateUserTakenLessonApi(formData);
            // if (response.status === 200) {
            //     setSuccess(true);
            //     setError(false);
            //     setLoading(false);
            //     setLessonCountDb(lessonCount);
            // }
            // else {
            //     setLoading(false);
            //     setSuccess(false);
            //     setError(true);
            // }

            setSuccess(true);
            setLoading(false);
            setLessonCountDb(10);

            let _dateList = [...dateList];
            _dateList.push(takenDate);
            setDateList(_dateList);
            setRemainingLessonCount(10 - _dateList.length);
            setTakenDate('');

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const deleteHandle = (item) => {
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            // const formData = new FormData();
            // formData.append('lessoncount', lessonCount);
            // formData.append('remaininglessoncount', remainingLessonCount);
            // const response = await updateUserTakenLessonApi(formData);
            // if (response.status === 200) {
            //     setSuccess(true);
            //     setError(false);
            //     setLoading(false);
            //     setLessonCountDb(lessonCount);
            // }
            // else {
            //     setLoading(false);
            //     setSuccess(false);
            //     setError(true);
            // }

            setSuccess(true);
            setLoading(false);
            setLessonCountDb(10);

            let _dateList = [...dateList];
            _dateList = _dateList.filter(x => x != item)
            setDateList(_dateList);
            setRemainingLessonCount(10 - _dateList.length);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }


    const renderUpdateUserTakenLesson = () => {
        return (
            <div style={{ margin: 20 }}>

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <h4>{resources.usertakenPageTitle}</h4>
                </div>

                {
                    !loading && success
                        ? <SuccessMessage successMessage={resources.usertakenPageSuccessMessage} />
                        : null
                }

                <div className="shadow p-3 mb-5 bg-white rounded">
                    <div style={{ margin: 20 }}>
                        <form onSubmit={handleSubmitTakenLesson}>
                            <div className="container">
                                {/* <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.usertakenPageLessonTitle}</label>
                                        <input type="number" className="form-control" value={lessonCount} onChange={lessonCountChange} />
                                    </div>
                                </div>
                            </div> */}
                                <h2 style={{ textAlign: 'center' }}>{resources.usertakenPageLessonTitle} - {lessonCountDb}</h2>
                                <h2 style={{ textAlign: 'center' }}>{resources.usertakenPageRemainingLessonTitle} - {remainingLessonCount}</h2>

                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.usertakenPageRemainingTakenDateTitle}</label>
                                        <input type="date" className="form-control" value={takenDate} onChange={takenDateChange} />
                                    </div>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.save}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="shadow p-3 mb-5 bg-white rounded">

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{resources.usertakenPageTakenDate}</th>
                                    <th>{resources.usertakenPageAction}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dateList && dateList.map((item) => (
                                    <tr key={item}>
                                        <td>{item}</td>
                                        <td><button type="button" className="btn btn-danger btn-sm" onClick={() => deleteHandle(item)}>{resources.delete}</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        )
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.errorMessage} />
    }


    return (
        <div style={{ margin: 20 }}>
            {
                lessonCountDb == 0
                    ? renderCreateUserTakenLesson()
                    : renderUpdateUserTakenLesson()
            }
        </div>
    )

}

export default UserTakenLesson;
