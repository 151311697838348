import { useState, useEffect } from "react";
import CustomModal from "../components/CustomModal";
import { resources } from '../helpers/Resource';
import api_url, { getLessonsApi, getUsersApi, deleteLessonApi } from '../util/api';
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage";
import './Lesson.css';

const Lessons = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalData, setModalData] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showUserModal, setUserShowModal] = useState(false);
    const navigate = useNavigate();

    const handleCloseModal = () => setShowModal(false);
    const handleCloseUserModal = () => setUserShowModal(false);


    const filteredData = data.filter((item) =>
        item.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        getLessons();
    }, []);

    const getLessons = async () => {
        setError(false);
        try {
            const response = await getLessonsApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const showRichText = (richText) => {
        setModalData(richText);
        setShowModal(true);
    }

    const editLesson = (item) => {
        navigate("/edit-lesson", { state: { item } });
    }

    const deleteLesson = async (lessonId) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('lessonId', lessonId);
            const response = await deleteLessonApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
                setSuccess(true);
            }
            else {
                setLoading(false);
                setError(true);
                setSuccess(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            setSuccess(false);
        }
    }

    const downloadFile = (fileName) => {
        const url = api_url + "/api/lesson/download-lesson-file?filePath=" + fileName;
        fetch(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = 'file';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => console.error(error));
    }

    const getIcon = (id, contentType, fileName) => {

        if (contentType == null || contentType == '')
            return null;

        if (contentType.endsWith('image/jpeg')) {
            return <i key={id} className="fas fa-image" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>
        }
        else if (contentType.endsWith('image/png')) {
            return <i key={id} className="fas fa-image" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>
        }
        else if (contentType.endsWith('application/pdf')) {
            return <i key={id} className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>
        }
        else if (contentType.endsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            return <i key={id} className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>

        }
        else if (contentType.endsWith('video/mp4')) {
            return <i key={id} className="fas fa-fw fa-video" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>
        }

        return <i key={id} className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(fileName)}></i>
    }

    const showUsers = (userIds) => {
        if (userIds.length > 0)
            getUsers(userIds)
    }

    const getUsers = async (userIds) => {
        setLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            for (let i = 0; i < userIds.length; i++) {
                formData.append('userIds', userIds[i]);
            }
            const response = await getUsersApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setUserData(response.data);
                setUserShowModal(true);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleUserDetail = (id) => {
        navigate("/user-detail", { state: { id } });
    }

    const getUserList = () => {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{resources.lessonsPageUserUsername}</th>
                        <th>{resources.lessonsPageUserEmail}</th>
                        <th>{resources.lessonsPageUserDetail}</th>
                    </tr>
                </thead>
                <tbody>
                    {userData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => handleUserDetail(item.id)}>{resources.lessonsPageUserDetail}</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.lessonsErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            {showModal && (
                <CustomModal isOpen={showModal} onClose={handleCloseModal} title='RichText'>
                    <div dangerouslySetInnerHTML={{ __html: modalData }}></div>
                </CustomModal>
            )}
            {showUserModal && (
                <CustomModal isOpen={showUserModal} onClose={handleCloseUserModal} title='User'>
                    {getUserList()}
                </CustomModal>
            )}
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.lessonsSuccessMessage} />
                    : null
            }
            <div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Lesson Title"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                        <span style={{ marginRight: 10 }}>Count per page:</span>
                        <div className="btn-group me-2" role="group" aria-label="First group">
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{resources.lessonsPageLessonTitle}</th>
                                <th>{resources.lessonsPageCreatedDate}</th>
                                <th>{resources.lessonsPageRichText}</th>
                                <th>{resources.lessonsPageFiles}</th>
                                <th>{resources.lessonsPageUserCount}</th>
                                <th>{resources.lessonsPageEdit}</th>
                                <th>{resources.lessonsPageDelete}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.lessonTitle}</td>
                                    <td>{item.createdDateString}</td>
                                    <td>
                                        <button type="button" className="btn btn-info btn-sm" onClick={() => showRichText(item.richText)}>{resources.lessonsShowRichText}</button>
                                    </td>
                                    <td>
                                        {
                                            item.lessonFiles.map((item) => {
                                                // return <span key={item.id} style={{ cursor: 'pointer', fontSize: 13, color: 'black', margin: 3, textDecoration: 'underline' }} onClick={() => downloadFile(item.fileName)}>{item.fileName}</span>
                                                return getIcon(item.id, item.contentType, item.fileName)
                                            })
                                        }
                                    </td>
                                    <td style={item.userIds.length > 0 ? { cursor: 'pointer', textDecoration: 'underline', color: '#4e73df' } : {}} onClick={() => showUsers(item.userIds)}>
                                        {item.userIds.length}
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-success btn-sm" onClick={() => editLesson(item)}>{resources.lessonsEdit}</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteLesson(item.id)}>{resources.lessonsDelete}</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                        Previous
                    </button>
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                        Next
                    </button>
                    <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                </div>
            </div>
        </div >
    );
}

export default Lessons;