import { useContext, useEffect, useState } from "react";
import { assignLessonApi, assignLessonListModelApi } from "../util/api";
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import UserDetailContext from "../store/userdetail-context";

const AssignLesson = () => {
    const userDtlCtx = useContext(UserDetailContext);
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLimitless, setIsLimitless] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    useEffect(() => {
        getLessons();
    }, []);

    const getLessons = async () => {
        setError(false);
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('userID', userDtlCtx.user.id);
            const response = await assignLessonListModelApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.userDetailAssignLessonErrorMessage} />
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('isLimitless', isLimitless);
            formData.append('lessonId', selectedOption);
            formData.append('userId', userDtlCtx.user.id);
            const response = await assignLessonApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setSelectedOption(null);
                setIsLimitless(false);
                setStartDate('');
                setEndDate('');
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const limitlessChange = (event) => {
        setIsLimitless(!isLimitless);
    }

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        const find = data.find(x => x.lessonId == event.target.value);
        setStartDate(find.startDate || '');
        setEndDate(find.endDate || '');
        setIsLimitless(find.isLimitless);
    }

    const buttonValidation = () => {
        if (isLimitless) {
            return false;
        }
        else {
            if (startDate == null || startDate == '') {
                return true;
            }

            if (endDate == null || endDate == '') {
                return true;
            }
        }
    }

    return (
        <div style={{ margin: 20 }}>

            {
                success && !loading ?
                    <div className="alert alert-success" role="alert">
                        {resources.userDetailAssingLessonSuccessMessage}
                    </div>
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} aria-label="Default select example" onChange={handleChange}>
                                    <option value="">{resources.userDetailSelectLesson}</option>
                                    {
                                        data.map((item) => {
                                            return <option key={item.lessonId} value={item.lessonId}>{item.lessonName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    {
                        selectedOption != null ?
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3"></div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={isLimitless} id="flexCheckDefault" onChange={limitlessChange} />
                                            <label className="form-check-label">
                                                {resources.userDetailIsLimitless}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !isLimitless ?
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3"></div>
                                                    <div className="form-check">
                                                        <input type="date" id="startdate" name="startdate" value={startDate} onChange={handleStartDateChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3"></div>
                                                    <div className="form-check">
                                                        <input type="date" id="enddate" name="enddate" value={endDate} onChange={handleEndDateChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div style={{ marginTop: 20 }}>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-primary btn-block" type="submit" disabled={loading || buttonValidation()}>{loading ? resources.loading : resources.userDetailSaveLessonButton}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </form>
        </div >
    );
}

export default AssignLesson;