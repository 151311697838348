import { useState, useContext, useEffect } from "react";
import CustomModal from "../components/CustomModal";
import { resources } from '../helpers/Resource';
import { getLogsApi } from '../util/api';
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";

const Logs = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const filteredData = data.filter((item) =>
        item.logDetail.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getLogsApi();
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            {
                currentRows && currentRows.length > 0
                    ? <div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={resources.search}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>Count per page:</span>
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }}>{resources.logsPageThCreatedDate}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.logsPageThLog}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.createdDateString}</td>
                                            <td>{item.logDetail}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                    Previous
                                </button>
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                    Next
                                </button>
                                <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                            </div>
                        </div>
                    </div>
                    : <div class="alert alert-warning" role="alert">
                        {resources.logsPageNoData}
                    </div>
            }

        </div>
    )
}


export default Logs;