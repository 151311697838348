import { useContext, useEffect } from "react";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const authCtx = useContext(AuthContext);

    useEffect(() => {
        authCtx.logout();
        navigate("/login");
    }, []);

    return (<>
    </>)
}

export default Logout;