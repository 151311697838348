
import axios from 'axios';
//const api_url = 'http://asdfsadfasdf.store';
const api_url = "https://blbfit.it";
//const api_url = 'http://localhost:5038';

export default api_url;

export async function login(email, password, comeFromAdminPanel) {
  const response = await axios.post(`${api_url}/security/createToken`, { email, password, comeFromAdminPanel });
  if (response.status != 200) {
    throw new Response('Failed to login process.', { status: 500 });
  }
  return response.data;
}

export async function createLessonApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/createLesson`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveWorkoutToUserPDFApi(formData) {
  const response = await axios.post(`${api_url}/api/workout/saveworkout`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveUserMeasurementInfo(formData) {
  const response = await axios.post(`${api_url}/api/user/user-measurement-form-admin`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveUserMeasurementPdfInfo(formData) {
  const response = await axios.post(`${api_url}/api/user/user-measurement-form-pdf-admin`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function sendPushNotification(formData) {
  const response = await axios.post(`${api_url}/api/user/send-push-notification`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function sendPushNotifications(formData) {
  const response = await axios.post(`${api_url}/api/user/send-push-notification-everybody`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveBioimpedanceAnalysis(formData) {
  const response = await axios.post(`${api_url}/api/user/save-bioimpedance-analysis`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getWorkoutUserFilesApi(formData) {
  const response = await axios.post(`${api_url}/api/workout/getuserworkoutfiles`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getBioimpedanceAnalysisApi(formData) {
  const response = await axios.post(`${api_url}/api/user/get-bioimpedance-analysis`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteBioimpedanceAnalysisApi(formData) {
  const response = await axios.post(`${api_url}/api/user/delete-bioimpedance-analysis`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteUWorDietApi(formData) {
  const response = await axios.post(`${api_url}/api/user/delete-uw-or-diet`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteDietApi(formData) {
  const response = await axios.post(`${api_url}/api/user/delete-diet`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteUserMeasurementApi(formData) {
  const response = await axios.post(`${api_url}/api/user/delete-user-measurement`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function saveDietToUserPDFApi(formData) {
  const response = await axios.post(`${api_url}/api/workout/savediet`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getDietUserFilesApi(formData) {
  const response = await axios.post(`${api_url}/api/workout/getuserdietfiles`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getUserMeasurementInformationListApi(formData) {
  const response = await axios.post(`${api_url}/api/user/getusermeasurementinformationlist`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getLessonsApi() {

  const response = await axios.post(`${api_url}/api/lesson/getLessons`, null, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function editLessonApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/edit-lesson`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getLogsApi(formData) {
  const response = await axios.post(`${api_url}/api/logs/getlogs`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getUsersApi(formData) {
  const response = await axios.post(`${api_url}/api/user/getusers`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getUserDetailApi(formData) {
  const response = await axios.post(`${api_url}/api/user/getuserdetail`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getTimeRangesApi() {
  const response = await axios.post(`${api_url}/api/user/gettimeranges`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function saveUserMeasurementApi(formData) {
  const response = await axios.post(`${api_url}/api/user/saveusermeasurement`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getHomepageDataApi() {
  const response = await axios.post(`${api_url}/api/homepage/gethomepagedata`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function assignLessonApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/assignLesson`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function usersLessonsApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/userslessons`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteUserLessonApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/delete-user-lesson`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function assignLessonListModelApi(formData) {
  const response = await axios.post(`${api_url}/api/lesson/assignlessonlistmodel`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getTrainersApi() {
  const response = await axios.post(`${api_url}/api/trainer/get-trainers`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveTrainerApi(formData) {
  const response = await axios.post(`${api_url}/api/trainer/savetrainer`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteTrainerApi(formData) {
  const response = await axios.post(`${api_url}/api/trainer/deletetrainer`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getRoomsApi() {
  const response = await axios.post(`${api_url}/api/room/getrooms`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveRoomApi(formData) {
  const response = await axios.post(`${api_url}/api/room/saveroom`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteRoomApi(formData) {
  const response = await axios.post(`${api_url}/api/room/deleteroom`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getFitnessClassesApi() {
  const response = await axios.post(`${api_url}/api/fitnessclass/getfitnessclasses`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveFitnessClassApi(formData) {
  const response = await axios.post(`${api_url}/api/fitnessclass/savefitnessclass`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteFitnessClassApi(formData) {
  const response = await axios.post(`${api_url}/api/fitnessclass/deletefitnessclass`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getCategoriesApi() {
  const response = await axios.post(`${api_url}/api/fitnessclass/getcategories`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getDaysApi() {
  const response = await axios.post(`${api_url}/api/fitnessclass/getdays`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getTimesApi() {
  const response = await axios.post(`${api_url}/api/fitnessclass/gettimes`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}



export async function getCalendarSettingsApi() {
  const response = await axios.post(`${api_url}/api/calendar/getcalendarsettings`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getPrimarySettingsApi(formData) {
  const response = await axios.post(`${api_url}/api/calendar/getprimarysettings`, formData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveCalendarPrimarySettingApi(fromData) {
  const response = await axios.post(`${api_url}/api/calendar/savecalendarprimarysetting`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveBlockedDaysApi(fromData) {
  const response = await axios.post(`${api_url}/api/calendar/saveblockeddays`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveBlockedDaysWithHourApi(fromData) {
  const response = await axios.post(`${api_url}/api/calendar/saveblockeddayswithhour`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getBlockedDaysApi(fromData) {
  const response = await axios.post(`${api_url}/api/calendar/getblockeddays`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function deleteCalendarBlockedDayApi(fromData) {
  const response = await axios.post(`${api_url}/api/calendar/deletecalenderblockedday`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteLessonApi(fromData) {
  const response = await axios.post(`${api_url}/api/lesson/delete-lesson`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveVideoLessonApi(fromData) {
  const response = await axios.post(`${api_url}/api/lesson/savevideolesson`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getVideoLessonsApi(fromData) {
  const response = await axios.post(`${api_url}/api/lesson/getvideolessons`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function deleteVideoLessonApi(fromData) {
  const response = await axios.post(`${api_url}/api/lesson/deletevideolesson`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getUsersVideoLessonsApi(fromData) {
  const response = await axios.post(`${api_url}/api/user/getusersvideolessonsforadmin`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function assignUsersVideoLessonApi(fromData) {
  const response = await axios.post(`${api_url}/api/user/assignusersvideolesson`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function getWhatsappIntegrationApi() {
  const response = await axios.post(`${api_url}/api/Communication/getwhatsappintegration`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function saveOrUpdateWhatsappIntegrationApi(fromData) {
  const response = await axios.post(`${api_url}/api/Communication/saveorupdatewhatsappintegration`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function saveOrUpdateUserBasicInfoApi(fromData) {
  const response = await axios.post(`${api_url}/api/user/saveorupdateuserbasicinfo`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getPromosApi() {
  const response = await axios.post(`${api_url}/api/promo/get-all-promos`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function makePassivePromoApi(fromData) {
  const response = await axios.post(`${api_url}/api/promo/make-passive`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function makeActivePromoApi(fromData) {
  const response = await axios.post(`${api_url}/api/promo/make-active`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}



export async function savePromoApi(fromData) {
  const response = await axios.post(`${api_url}/api/promo/save-promo`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function getNewsApi() {
  const response = await axios.post(`${api_url}/api/news/get-all-news`, null, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}

export async function makePassiveNewsApi(fromData) {
  const response = await axios.post(`${api_url}/api/news/make-passive`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}


export async function makeActiveNewsApi(fromData) {
  const response = await axios.post(`${api_url}/api/news/make-active`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}



export async function saveNewsApi(fromData) {
  const response = await axios.post(`${api_url}/api/news/save-news`, fromData, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  });
  if (response.status != 200) {
    throw new Response('Failed process.', { status: 500 });
  }
  return response;
}