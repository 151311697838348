import { useState, useEffect } from "react";
import PrimarySettings from "../components/Calendar/PrimarySettings";
import RemoveBlockedDay from "../components/Calendar/RemoveBlockedDay";
import SpecificCalendar from "../components/Calendar/SpecificCalendar";
import SpecificHour from "../components/Calendar/SpecificHour";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import SuccessMessage from "../components/SuccessMessage";
import { resources } from "../helpers/Resource";
import { getCalendarSettingsApi, getTrainersApi } from "../util/api";



const Calendar = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [settingOption, setSettingOption] = useState('');
    const [settings, setSettings] = useState([]);
    const [trainerOption, setTrainerOption] = useState('');
    const [trainers, setTrainers] = useState([]);

    useEffect(() => {
        getCalendarSettings();
        getTrainers();
    }, []);


    const getTrainers = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTrainersApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setTrainers(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const getCalendarSettings = async () => {
        //setLoading(true);
        setError(false);
        try {
            const response = await getCalendarSettingsApi();
            if (response.status === 200) {
                //setLoading(false);
                //setError(false);
                setSettings(response.data);
            }
            else {
                //setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleTrainerChange = (event) => {
        setTrainerOption(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            // const formData = new FormData();
            // formData.append('title', title);
            // formData.append('categoryId', categoryOption);
            // formData.append('day', dayOption);
            // formData.append('from', fromOption);
            // formData.append('to', toOption);
            // formData.append('description', richText);
            // formData.append('id', id);
            // formData.append('trainerId', trainerOption);

            // const response = await saveFitnessClassApi(formData);
            // if (response.status === 200) {
            //     setLoading(false);
            //     setSuccess(true);
            //     setError(false);
            //     setTitle('');
            //     setRichText('');
            //     setId(0);
            //     setCategoryOption('');
            //     setDayOption('');
            //     setFromOption('');
            //     setToOption('');
            //     setRichText('');
            //     setTrainerOption('');
            //     getFitnessClasses();
            // }
            // else {
            //     setLoading(false);
            //     setSuccess(false);
            //     setError(true);
            // }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSettingChange = (event) => {
        setSettingOption(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.calendarPageErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{resources.calendarPageHeaderTitle}</h1>
            </div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.calendarPageSuccessMessage} />
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPageTrainer}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={trainerOption} aria-label="Select" onChange={handleTrainerChange}>
                                    <option value="">Select a Trainer</option>
                                    {
                                        trainers.map((item) => {
                                            return <option key={`trainer_${item.id}`} value={item.id}>{item.fullName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    {
                        trainerOption != '' && !loading
                            ? <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.calendarPageSelectSettingType}</label>
                                        <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={settingOption} aria-label="Select" onChange={handleSettingChange}>
                                            <option value="">Select a Setting Type</option>
                                            {
                                                settings.map((item) => {
                                                    return <option key={item.key} value={item.key}>{item.value}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                </div>

            </form>

            {
                settingOption == ''
                    ? null
                    : settingOption == '1'
                        ? <PrimarySettings trainerId={trainerOption}/>
                        : settingOption == '2'
                            ? <SpecificCalendar trainerId={trainerOption}/>
                            : settingOption == '3'
                                ? <SpecificHour trainerId={trainerOption}/>
                                : settingOption == '4'
                                    ? <RemoveBlockedDay trainerId={trainerOption}/>
                                    : null
            }
        </div>
    )
}

export default Calendar;