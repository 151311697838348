import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { resources } from "../../helpers/Resource";
import { deleteCalendarBlockedDayApi, getBlockedDaysApi, getUsersApi } from "../../util/api";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";

const RemoveBlockedDay = ({ trainerId }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentRichText, setCurrentRichText] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    // Filter data based on search term
    const filteredData = data.filter((item) =>
        item.blockedDateString.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.blockedEndString.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calculate index of the last row on current page
    const indexOfLastRow = currentPage * rowsPerPage;

    // Calculate index of the first row on current page
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    // Slice the data array to show only rows for the current page
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    // Create an array of page numbers based on the number of rows and rows per page
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        getBlockedDays();
    }, []);

    const deleteRow = (id) => {
        deleteCalendarBlockedDay(id);
    }

    const deleteCalendarBlockedDay = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('trainerId', trainerId);
            const response = await deleteCalendarBlockedDayApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
                setSuccess(true);
            }
            else {
                setLoading(false);
                setError(true);
                setSuccess(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            setSuccess(false);
        }
    }

    const getBlockedDays = async () => {
        setLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            formData.append('trainerId', trainerId);
            const response = await getBlockedDaysApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.lessonsErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.calendarPageDeleteBlockedSuccessMessage} />
                    : null
            }
            <div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Date"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                        <span style={{ marginRight: 10 }}>Count per page:</span>
                        <div className="btn-group me-2" role="group" aria-label="First group">
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                            <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                        </div>
                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((item) => (
                            <tr key={item.id}>
                                <td>{item.blockedDateString}</td>
                                <td>{item.blockedEndString}</td>
                                <td>
                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteRow(item.id)}>{resources.calendarPageDeleteBlockedDateDetail}</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                        Previous
                    </button>
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                        Next
                    </button>
                    <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                </div>
            </div>
        </div >
    )
}

export default RemoveBlockedDay;