import { useState, useEffect } from "react";
import { resources } from "../../helpers/Resource";
import { getPrimarySettingsApi, getTimesApi, saveCalendarPrimarySettingApi } from "../../util/api";
import ErrorMessage from "../ErrorMessage";
import Loading from "../Loading";
import SuccessMessage from "../SuccessMessage";

const PrimarySettings = ({ trainerId }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [mondayStartTime, setMondayStartTime] = useState('');
    const [mondayEndTime, setMondayEndTime] = useState('');
    const [tuesdayStartTime, setTuesdayStartTime] = useState('');
    const [tuesdayEndTime, setTuesdayEndTime] = useState('');
    const [wednesdayStartTime, setWednesdayStartTime] = useState('');
    const [wednesdayEndTime, setWednesdayEndTime] = useState('');
    const [thursdayStartTime, setThursdayStartTime] = useState('');
    const [thursdayEndTime, setThursdayEndTime] = useState('');
    const [fridayStartTime, setFridayStartTime] = useState('');
    const [fridayEndTime, setFridayEndTime] = useState('');
    const [saturdayStartTime, setSaturdayStartTime] = useState('');
    const [saturdayEndTime, setSaturdayEndTime] = useState('');
    const [sundayStartTime, setSundayStartTime] = useState('');
    const [sundayEndTime, setSundayEndTime] = useState('');
    const [data, setData] = useState({});
    const [times, setTimes] = useState([]);

    useEffect(() => {
        getPrimarySettings();
        getTimes();
    }, []);

    const getPrimarySettings = async () => {
        setLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            formData.append('trainerId', trainerId);
            const response = await getPrimarySettingsApi(formData);
            if (response.status === 200) {
                setError(false);
                setData(response.data);
                setMondayStartTime(response.data.mondayStartTimeString == null ? "09:00" : response.data.mondayStartTimeString);
                setMondayEndTime(response.data.mondayEndTimeString == null ? "18:00" : response.data.mondayEndTimeString);
                setTuesdayStartTime(response.data.tuesdayStartTimeString == null ? "09:00" : response.data.tuesdayStartTimeString);
                setTuesdayEndTime(response.data.tuesdayEndTimeString == null ? "18:00" : response.data.tuesdayEndTimeString);
                setWednesdayStartTime(response.data.wednesdayStartTimeString == null ? "09:00" : response.data.wednesdayStartTimeString);
                setWednesdayEndTime(response.data.wednesdayEndTimeString == null ? "18:00" : response.data.wednesdayEndTimeString);
                setThursdayStartTime(response.data.thursdayStartTimeString == null ? "09:00" : response.data.thursdayStartTimeString);
                setThursdayEndTime(response.data.thursdayEndTimeString == null ? "18:00" : response.data.thursdayEndTimeString);
                setFridayStartTime(response.data.fridayStartTimeString == null ? "09:00" : response.data.fridayStartTimeString);
                setFridayEndTime(response.data.fridayEndTimeString == null ? "18:00" : response.data.fridayEndTimeString);
                setSaturdayStartTime(response.data.saturdayStartTimeString == null ? "09:00" : response.data.saturdayStartTimeString);
                setSaturdayEndTime(response.data.saturdayEndTimeString == null ? "18:00" : response.data.saturdayEndTimeString);
                setSundayStartTime(response.data.sundayStartTimeString == null ? "09:00" : response.data.sundayStartTimeString);
                setSundayEndTime(response.data.sundayEndTimeString == null ? "18:00" : response.data.sundayEndTimeString);
                setLoading(false);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const getTimes = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTimesApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setTimes(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('obj.mondayStartTime', mondayStartTime);
            formData.append('obj.mondayEndTime', mondayEndTime);
            formData.append('obj.tuesdayStartTime', tuesdayStartTime);
            formData.append('obj.tuesdayEndTime', tuesdayEndTime);
            formData.append('obj.wednesdayStartTime', wednesdayStartTime);
            formData.append('obj.wednesdayEndTime', wednesdayEndTime);
            formData.append('obj.thursdayStartTime', thursdayStartTime);
            formData.append('obj.thursdayEndTime', thursdayEndTime);
            formData.append('obj.fridayStartTime', fridayStartTime);
            formData.append('obj.fridayEndTime', fridayEndTime);
            formData.append('obj.saturdayStartTime', saturdayStartTime);
            formData.append('obj.saturdayEndTime', saturdayEndTime);
            formData.append('obj.sundayStartTime', sundayStartTime);
            formData.append('obj.sundayEndTime', sundayEndTime);
            formData.append('obj.trainerId', trainerId);
            const response = await saveCalendarPrimarySettingApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
                setSuccess(true);
                setMondayStartTime(response.data.mondayStartTimeString);
                setMondayEndTime(response.data.mondayEndTimeString);
                setTuesdayStartTime(response.data.tuesdayStartTimeString);
                setTuesdayEndTime(response.data.tuesdayEndTimeString);
                setWednesdayStartTime(response.data.wednesdayStartTimeString);
                setWednesdayEndTime(response.data.wednesdayEndTimeString);
                setThursdayStartTime(response.data.thursdayStartTimeString);
                setThursdayEndTime(response.data.thursdayEndTimeString);
                setFridayStartTime(response.data.fridayStartTimeString);
                setFridayEndTime(response.data.fridayEndTimeString);
                setSaturdayStartTime(response.data.saturdayStartTimeString);
                setSaturdayEndTime(response.data.saturdayEndTimeString);
                setSundayStartTime(response.data.sundayStartTimeString);
                setSundayEndTime(response.data.sundayEndTimeString);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            setSuccess(false);
        }
    }

    const handleMondayStartChange = (event) => {
        setMondayStartTime(event.target.value);
    }

    const handleMondayEndChange = (event) => {
        setMondayEndTime(event.target.value);
    }

    const handleTuesdayStartChange = (event) => {
        setTuesdayStartTime(event.target.value);
    }

    const handleTuesdayEndChange = (event) => {
        setTuesdayEndTime(event.target.value);
    }

    const handleWednesdayStartChange = (event) => {
        setWednesdayStartTime(event.target.value);
    }

    const handleWednesdayEndChange = (event) => {
        setWednesdayEndTime(event.target.value);
    }

    const handleThursdayStartChange = (event) => {
        setThursdayStartTime(event.target.value);
    }

    const handleThursdayEndChange = (event) => {
        setThursdayEndTime(event.target.value);
    }

    const handleFridayStartChange = (event) => {
        setFridayStartTime(event.target.value);
    }

    const handleFridayEndChange = (event) => {
        setFridayEndTime(event.target.value);
    }

    const handleSaturdayStartChange = (event) => {
        setSaturdayStartTime(event.target.value);
    }

    const handleSaturdayEndChange = (event) => {
        setSaturdayEndTime(event.target.value);
    }

    const handleSundayStartChange = (event) => {
        setSundayStartTime(event.target.value);
    }

    const handleSundayEndChange = (event) => {
        setSundayEndTime(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.calendarPageErrorMessage} />
    }

    return (
        <div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.calendarPageSuccessMessage} />
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPagePrimarySettingDays}</label>
                                <input type="text" id="monday" name="monday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingMonday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPagePrimarySettingStartTime}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={mondayStartTime} aria-label="Select" onChange={handleMondayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`mondayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPagePrimarySettingEndTime}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={mondayEndTime} aria-label="Select" onChange={handleMondayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`mondayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="monday" name="monday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingTuesday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={tuesdayStartTime} aria-label="Select" onChange={handleTuesdayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`tuesdayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={tuesdayEndTime} aria-label="Select" onChange={handleTuesdayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`tuesdayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="wednesday" name="wednesday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingWednesday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={wednesdayStartTime} aria-label="Select" onChange={handleWednesdayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`wednesdayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={wednesdayEndTime} aria-label="Select" onChange={handleWednesdayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`wednesdayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="thursday" name="thursday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingThursday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={thursdayStartTime} aria-label="Select" onChange={handleThursdayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`thursdayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={thursdayEndTime} aria-label="Select" onChange={handleThursdayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`thursdayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="friday" name="friday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingFriday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={fridayStartTime} aria-label="Select" onChange={handleFridayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`fridayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={fridayEndTime} aria-label="Select" onChange={handleFridayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`fridayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="saturday" name="saturday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingSaturday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={saturdayStartTime} aria-label="Select" onChange={handleSaturdayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`saturdayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={saturdayEndTime} aria-label="Select" onChange={handleSaturdayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`saturdayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <input type="text" id="sunday" name="sunday" disabled={true} className="form-control" value={resources.calendarPagePrimarySettingSunday} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={sundayStartTime} aria-label="Select" onChange={handleSundayStartChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`sundayStartTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={sundayEndTime} aria-label="Select" onChange={handleSundayEndChange}>
                                    <option value=""></option>
                                    {
                                        times.map((item) => {
                                            return <option key={`sundayEndTime${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.calendarPagePrimarySettingUpdateButton}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PrimarySettings;