import { useReducer } from 'react';

import UserDetailContext from './userdetail-context';

const defaultUserDetailState = {
    user: {}
};

const userDetailReducer = (state, action) => {
    if (action.type === 'ADD') {
        return {
            user: action.item
        };
    }

    return defaultUserDetailState;
};

const UserDetailProvider = props => {
    const [userDetailState, dispatchuserDetailAction] = useReducer(userDetailReducer, defaultUserDetailState);

    const addUserHandler = item => {
        dispatchuserDetailAction({ type: 'ADD', item: item });
    };

    const userDetailContext = {
        user: userDetailState.user,
        addUser: addUserHandler,
    }

    return <UserDetailContext.Provider value={userDetailContext}>
        {props.children}
    </UserDetailContext.Provider>
};

export default UserDetailProvider;