import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomModal.css'; // import CSS styles for the modal

function CustomModal({ isOpen, onClose, title, children }) {
  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    // set the modal container's CSS styles based on whether it's open or closed
    if (isOpen) {
      setModalStyle({ display: 'block' });
    } else {
      setModalStyle({ display: 'none' });
    }
  }, [isOpen]);

  // handle closing the modal when the user clicks outside of it
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal" style={modalStyle} onClick={handleOutsideClick}>
      <div className="modal-dialog" style={{maxWidth:'none', width: '90%'}}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleOutsideClick}>Close</button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomModal;
