import { useEffect, useState } from "react";
import { resources } from "../../helpers/Resource";
import { getTimesApi, saveBlockedDaysApi, saveBlockedDaysWithHourApi, saveCalendarPrimarySettingApi } from "../../util/api";
import ErrorMessage from "../ErrorMessage";
import Loading from "../Loading";
import SuccessMessage from "../SuccessMessage";

const SpecificHour = ({ trainerId }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [dates, setDates] = useState([]);
    const [times, setTimes] = useState([]);
    const [dateOption, setDateOption] = useState('');
    const [fromOption, setFromOption] = useState('');
    const [toOption, setToOption] = useState('');

    useEffect(() => {
        getTimes();
    }, []);

    const getTimes = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTimesApi();
            if (response.status === 200) {
                setError(false);
                setTimes(response.data);
                let _dates = [];
                let currentDate = new Date();
                for (let i = 0; i < 6; i++) {
                    let nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
                    let lastDate = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0);
                    for (let j = 1; j <= lastDate.getDate(); j++) {
                        let day = new Date(nextDate.getFullYear(), nextDate.getMonth(), j);
                        if (day >= currentDate) {
                            _dates.push(day.toISOString().substring(0, 10));
                        }
                    }
                }
                setDateOption(_dates[0]);
                setDates(_dates);
                setLoading(false);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('date', dateOption);
            formData.append('from', fromOption);
            formData.append('to', toOption);
            formData.append('trainerId', trainerId);
            const response = await saveBlockedDaysWithHourApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setDateOption('');
                setFromOption('');
                setToOption('');
                setSuccess(true);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            setSuccess(false);
        }
    }

    const handleDateChange = (event) => {
        setDateOption(event.target.value);
    }

    const handleFromChange = (event) => {
        setFromOption(event.target.value);
    }

    const handleToChange = (event) => {
        setToOption(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.calendarPageSpecificHourErrorMesssage} />
    }

    return (
        <div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.calendarPageSpecificHourSuccessMessage} />
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPageSpecificHourDate}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={dateOption} aria-label="Select" onChange={handleDateChange}>
                                    <option value="">Select a Day</option>
                                    {
                                        dates.map((item) => {
                                            return <option key={`trainer_${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageFrom}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={fromOption} aria-label="Select" onChange={handleFromChange}>
                                    <option value="">From</option>
                                    {
                                        times.map((item) => {
                                            return <option key={`from_${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageTo}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={toOption} aria-label="Select" onChange={handleToChange}>
                                    <option value="">To</option>
                                    {
                                        times.map((item) => {
                                            return <option key={`to_${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.calendarPageSpecificHourSaveButton}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SpecificHour;