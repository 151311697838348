import { useEffect, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { resources } from "../helpers/Resource";
import { deleteRoomApi, getRoomsApi, saveRoomApi } from "../util/api";

const Room = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [id, setId] = useState(0);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    const roomNameChange = (event) => {
        setRoomName(event.target.value);
    }

    useEffect(() => {
        getRooms();
    }, []);

    const getRooms = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getRoomsApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('roomName', roomName);
            formData.append('id', id);
            const response = await saveRoomApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setRoomName('');
                setData(response.data);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const deleteRoom = async (id) => {
        setLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            formData.append('roomId', id);
            const response = await deleteRoomApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.roomPageErrorMessage} />
    }

    const handleEditButton = (roomName, id) => {
        setRoomName(roomName);
        setId(id);
    }

    return (
        <div style={{ margin: 20 }}>
            <h4>{resources.roomPageCreateRoomTitle}</h4>
            <div className="shadow p-3 mb-5 bg-white rounded">
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">{resources.roomPageRoomName}</label>
                                    <input type="text" className="form-control" id="roomm" value={roomName} onChange={roomNameChange} />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-primary btn-block" type="submit" disabled={loading || roomName == ''}>{loading ? resources.loading : resources.roomPageSaveButton}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div style={{ marginHorizontal: 20 }}>
                <h4>{resources.roomPageRoomsTitle}</h4>
                <div className="shadow p-3 mb-5 bg-white rounded">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                        <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                            <span style={{ marginRight: 10 }}>Count per page:</span>
                            <div className="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{resources.roomPageThRoomName}</th>
                                <th style={{ textAlign: 'center' }}>{resources.roomPageThEdit}</th>
                                <th style={{ textAlign: 'center' }}>{resources.roomPageThDelete}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.roomName}</td>
                                    <td  style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => handleEditButton(item.roomName, item.id)}>{resources.roomPageEditButton}</button>
                                    </td>
                                    <td  style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteRoom(item.id)}>{resources.roomPageDeleteButton}</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                            Previous
                        </button>
                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                            Next
                        </button>
                        <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Room;