
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editLessonApi } from '../util/api';
import { resources } from '../helpers/Resource';
import { useLocation, useNavigate } from 'react-router-dom';

const EditLesson = () => {
    const { state } = useLocation();
    const item = state?.item;
    const [lessonTitle, setLessonTitle] = useState(item.lessonTitle);
    const filesInput = useRef(null);
    const [richText, setRichText] = useState(item.richText);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        setCheckedList(item.lessonFiles.map(({ id, fileName }) => ({ id, fileName, checked: false })));
    }, []);

    const handleCheckboxChange = (id) => {
        let newArray = checkedList.map((obj) => {
            if (obj.id === id) {
                return { ...obj, checked: !obj.checked }
            }
            else {
                return obj
            }
        });
        setCheckedList(newArray);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            for (let i = 0; i < filesInput.current.files.length; i++) {
                formData.append('files', filesInput.current.files[i]);
            }

            const deleteFileIdList = checkedList.filter(x => x.checked == true).map(item => item.id);

            for (let i = 0; i < deleteFileIdList.length; i++) {
                formData.append('deleteFileids', deleteFileIdList[i]);
            }

            formData.append('lessonid', item.id);
            formData.append('lessonTitle', lessonTitle);
            formData.append('richText', richText);
            const response = await editLessonApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setLessonTitle('');
                setRichText('');
                filesInput.current.value = null;
            // navigate("/lessons");
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const lessonTitleChange = (event) => {
        setLessonTitle(event.target.value);
    }


    return (
        <div style={{ margin: 20 }}>
            {
                success && !loading ?
                    <div className="alert alert-success" role="alert">
                        {resources.editLessonSuccessMessage}
                    </div>
                    : null
            }
            {
                error && !loading ?
                    <div className="alert alert-danger" role="alert">
                        {resources.createLessonErrorMessage}
                    </div>
                    : null
            }
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Edit Page : {item.lessonTitle}</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.createLessonLessonTitle}</label>
                                <input type="text" className="form-control" id="createLessonLessonTitle" value={lessonTitle} onChange={lessonTitleChange} />
                            </div>
                        </div>
                    </div>

                    {
                        checkedList.length > 0
                            ? <div>
                                <h5 style={{ marginTop: 20 }}>{resources.editLessonSelectFiles}</h5>
                                <div className="row">
                                    <div className="col">
                                        {
                                            checkedList.map((item) => {
                                                return <div className="form-check" key={item.id}>
                                                    <label className="form-check-label">
                                                        <input className="form-check-input" type="checkbox" checked={item.checked} onChange={() => handleCheckboxChange(item.id)} />
                                                        {item.fileName}
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                    <h5 style={{ marginTop: 20 }}>{resources.editLessonAddNewFile}</h5>

                    <div className="row">
                        <div className="col">
                            <div className="input-group mb-3">
                                <label className="input-group-text">Upload</label>
                                <input type="file" multiple className="form-control" id="createLessonFiles" ref={filesInput} />
                            </div>
                        </div>
                    </div>

                    <ReactQuill theme="snow" value={richText} onChange={setRichText} modules={{
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                ['link', 'image', 'video'],
                                ['clean']
                            ],
                            formats: ['bold', 'italic', 'underline', 'strike', 'link', 'image', 'video', 'header', 'code-block', 'blockquote', 'list', 'align', 'background', 'color', 'indent', 'script']
                        },
                        clipboard: { matchVisual: true }
                    }} style={{ height: 300 }} />

                    <div style={{ marginTop: 60 }}>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-primary btn-block" type="submit" disabled={loading || lessonTitle == ''}>{loading ? resources.loading : resources.editLessonUpdateButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}

export default EditLesson;