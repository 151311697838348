import React, { useEffect, useState, useContext } from "react";
import UserDetailContext from '../store/userdetail-context';
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import api_url, { getUsersVideoLessonsApi, assignUsersVideoLessonApi } from '../util/api';
import YouTube from 'react-youtube';

const UserAssignVideo = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const userDtlCtx = useContext(UserDetailContext);

    const opts = {
        height: '300',
        width: '300',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,  // This will auto-play the video when loaded
        },
    };

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo(); // example: pause the video when ready
    }


    useEffect(() => {
        getUsersVideoLessons();
    }, []);

    const getUsersVideoLessons = async () => {
        setError(false);
        setSuccess(false);
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('userID', userDtlCtx.user.id);
            const response = await getUsersVideoLessonsApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setError(false);
                setLoading(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    const handleCheckboxChange = (videoId, assigned) => {
        try {
            let newData = [...data];
            let updatedDate = newData.find(x => x.videoId == videoId)
            updatedDate.assigned = !assigned;
            setData(newData);
        }
        catch {

        }
    };

    const onClickButton = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        const req = data.map((item) => {
            return {
                videoId: item.videoId,
                assigned: item.assigned,
                userId: userDtlCtx.user.id
            };
        });


        try {
            const formData = new FormData();
            req.forEach((item, index) => {
                formData.append(`item[${index}].videoId`, item.videoId);
                formData.append(`item[${index}].assigned`, item.assigned);
                formData.append(`item[${index}].userId`, userDtlCtx.user.id);
            });

            const response = await assignUsersVideoLessonApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setError(false);
                setSuccess(true);
                setLoading(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div style={{ margin: 20 }}>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.userDetailSaveWorkoutSuccessMessage} />
                    : null
            }
            {
                data && data.length > 0
                    ? <div className="shadow p-3 mb-5 bg-white rounded">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailAssignVideoAssigned}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailAssignVideoAssignedDate}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailAssignVideoAssignedPerson}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailAssignVideoVideoName}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailAssignVideoVideo}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.videoId}>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                <input
                                                    key={`check${item.videoId}`}
                                                    type="checkbox"
                                                    checked={item.assigned}
                                                    onChange={() => handleCheckboxChange(item.videoId, item.assigned)}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.assignedDateString}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.assignedFullName}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.videoName}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }} key={`video_${item.id}`}>
                                                {/* <video width="500" height='300' controls>
                                            <source src={item.videoPath} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> */}

                                                <YouTube videoId={item.videoPath} opts={opts} onReady={onPlayerReady} />

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-primary btn-block" onClick={onClickButton} type="submit" disabled={loading}>{loading ? resources.loading : resources.save}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div class="alert alert-danger" role="alert">
                        {resources.userDetailAssignNoVideo}
                    </div>
            }


        </div>
    )
};

export default UserAssignVideo;