import { useState, useEffect, useRef } from "react";
import { resources } from '../helpers/Resource';
import api_url, { getWhatsappIntegrationApi, saveOrUpdateWhatsappIntegrationApi } from '../util/api';
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage";

const WhatsappIntegration = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [phone, setPhone] = useState('');
    const [greetingMessage, setGreetingMessage] = useState('');

    useEffect(() => {
        getWhatsappIntegration();
    }, []);

    const getWhatsappIntegration = async () => {
        setLoading(true);
        setError(false);

        try {
            const response = await getWhatsappIntegrationApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                // setData(response.data);
                setPhone(response.data.mobileNumber);
                setGreetingMessage(response.data.greetingMessage);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('phonenumber', phone);
            formData.append('greetingmessage', greetingMessage);
            const response = await saveOrUpdateWhatsappIntegrationApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const phoneNameChange = (event) => {
        setPhone(event.target.value);
    }

    const greetingMessageChange = (event) => {
        setGreetingMessage(event.target.value);
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.whatsappIntegrationPageErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
             {
                !loading && success
                    ? <SuccessMessage successMessage={resources.whatsappIntegrationPageSuccessMessage} />
                    : null
            }
            <h3>{resources.whatsappIntegrationPageTitle}</h3>
            <div className="shadow p-3 mb-5 bg-white rounded">
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">{resources.whatsappIntegrationPhoneNumber}</label>
                                    <input type="text" className="form-control" id="createLessonLessonTitle" value={phone} onChange={phoneNameChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">{resources.whatsappIntegrationPageGreetingMessage}</label>
                                    <input type="text" className="form-control" id="title" value={greetingMessage} onChange={greetingMessageChange} />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-primary btn-block" type="submit" disabled={loading || phone == '' || greetingMessage == ''}>{loading ? resources.loading : resources.whatsappIntegrationPageSaveButton}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default WhatsappIntegration;
