import { useEffect, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { resources } from "../helpers/Resource";
import { getCategoriesApi, getTimesApi, saveFitnessClassApi, getDaysApi, getFitnessClassesApi, getTrainersApi } from "../util/api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SuccessMessage from "../components/SuccessMessage";
import './SaveFitnessClass.css';

const SaveFitnessClass = () => {
    const [categories, setCategories] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tempTableData, setTempTableData] = useState([]);
    const [times, setTimes] = useState([]);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [error, setError] = useState(false);
    const [errorTable, setErrorTable] = useState(false);
    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState('');
    const [categoryOption, setCategoryOption] = useState('');
    const [dayOption, setDayOption] = useState('');
    const [fromOption, setFromOption] = useState('');
    const [toOption, setToOption] = useState('');
    const [richText, setRichText] = useState('');
    const [trainerOption, setTrainerOption] = useState('');
    const [showDetail, setDetail] = useState(false);
    const [id, setId] = useState(0);
    const [trainers, setTrainers] = useState([]);

    useEffect(() => {
        getCategories();
        getTimes();
        getDays();
        getFitnessClasses();
        getTrainers();
    }, []);


    const getTrainers = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTrainersApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setTrainers(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const getFitnessClasses = async () => {
        setLoadingTable(true);
        setErrorTable(false);
        try {
            const response = await getFitnessClassesApi();
            if (response.status === 200) {
                setLoadingTable(false);
                setErrorTable(false);
                setTableData(response.data.filter(x => x.showRow));
                setTempTableData(response.data);
            }
            else {
                setLoadingTable(false);
                setErrorTable(true);
            }
        } catch (error) {
            setLoadingTable(false);
            setErrorTable(true);
        }
    }

    const getCategories = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getCategoriesApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setCategories(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const getTimes = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTimesApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setTimes(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const getDays = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getDaysApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setDays(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('categoryId', categoryOption);
            formData.append('day', dayOption);
            formData.append('from', fromOption);
            formData.append('to', toOption);
            formData.append('description', richText);
            formData.append('id', id);
            formData.append('trainerId', trainerOption);

            const response = await saveFitnessClassApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setTitle('');
                setRichText('');
                setId(0);
                setCategoryOption('');
                setDayOption('');
                setFromOption('');
                setToOption('');
                setRichText('');
                setTrainerOption('');
                getFitnessClasses();
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const titleChange = (event) => {
        setTitle(event.target.value);
    }

    const handleCatChange = (event) => {
        setCategoryOption(event.target.value);
    }

    const handleDayChange = (event) => {
        setDayOption(event.target.value);
    }

    const handleFromChange = (event) => {
        setFromOption(event.target.value);
    }

    const handleToChange = (event) => {
        setToOption(event.target.value);
    }

    const handleTrainerChange = (event) => {
        setTrainerOption(event.target.value);
    }

    const generateRow = (item, dayId, from) => {
        return (
            item != null
                ? item.map((subItem) => (
                    <div key={subItem.fitnessClassItem.id} style={{ cursor: 'pointer' }} onClick={() => clickDetail(subItem)}>
                        {subItem.title}
                    </div>
                ))
                : <div key={new Date().getTime()} style={{ cursor: 'pointer' }} onClick={() => clickAssignForEmpty(item, dayId, from)}>
                    &nbsp;
                </div>
        )
    }

    const clickAssignForEmpty = (item, dayId, from) => {
        setTitle('');
        setCategoryOption('');
        setDayOption(dayId);
        setFromOption(from);
        setToOption('');
        setRichText('');
        setId('');
        setTrainerOption('');
    }

    const clickDetail = (item) => {
        setTitle(item.fitnessClassItem.title);
        setCategoryOption(item.fitnessClassItem.categoryID);
        setDayOption(item.fitnessClassItem.day);
        setFromOption(item.fitnessClassItem.from);
        setToOption(item.fitnessClassItem.to);
        setRichText(item.fitnessClassItem.description);
        setId(item.fitnessClassItem.id);
        setTrainerOption(item.fitnessClassItem.trainerId);
    }

    const detailHandle = () => {
        const _showDetail = !showDetail;
        setDetail(!showDetail);

        if (_showDetail) {
            setTableData(tempTableData);
        }
        else {
            const data = tempTableData.filter(x => x.showRow);
            setTableData(data);
        }
    }

    const generateTable = () => {
        return (
            <div>
                <hr />
                <button type="button" className="btn btn-primary btn-sm" onClick={detailHandle}>
                    {showDetail ? resources.addClassPageHideEmptyRows : resources.addClassPageShowEmptyRows}
                </button>
                <table style={{ marginTop: 20 }}>
                    <thead>
                        <tr>
                            <th style={{ color: '#fff', textAlign: 'center', border: '1px solid black' }}></th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageMonday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageTuesday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageWednesday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageThursday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageFriday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageSaturday}</th>
                            <th style={{ color: '#fff', textAlign: 'center', backgroundColor: '#28A745', border: '1px solid black' }}>{resources.addClassPageSunday}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((item, index) => (
                                <tr key={`${index}${new Date().getTime()}`}>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{item.label}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[0], 'Monday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[1], 'Tuesday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[2], 'Wednesday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[3], 'Thursday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[4], 'Friday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[5], 'Saturday', item.label)}</td>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>{generateRow(item.rows[6], 'Sunday', item.label)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.addClassPageErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{resources.addClassPageHeaderTitle}</h1>
            </div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={id != 0 ? resources.addClassPageUpdateSuccessMessage : resources.addClassPageSuccessMessage} />
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageTitle}</label>
                                <input type="text" className="form-control" id="roomm" value={title} onChange={titleChange} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageCategory}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={categoryOption} aria-label="Select" onChange={handleCatChange}>
                                    <option value="">Select a Category</option>
                                    {
                                        categories.map((item) => {
                                            return <option key={item.id} value={item.id}>{item.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageDay}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={dayOption} aria-label="Select" onChange={handleDayChange}>
                                    <option value="">Select a Day</option>
                                    {
                                        days.map((item) => {
                                            return <option key={`day_${item.label}`} value={item.label}>{item.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageTrainer}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={trainerOption} aria-label="Select" onChange={handleTrainerChange}>
                                    <option value="">Select a Trainer</option>
                                    {
                                        trainers.map((item) => {
                                            return <option key={`trainer_${item.id}`} value={item.id}>{item.fullName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageFrom}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={fromOption} aria-label="Select" onChange={handleFromChange}>
                                    <option value="">From</option>
                                    {
                                        times.map((item) => {
                                            return <option key={`from_${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.addClassPageTo}</label>
                                <select style={{ border: '1 solid gray', width: '100%', height: 40, borderRadius: 5 }} value={toOption} aria-label="Select" onChange={handleToChange}>
                                    <option value="">To</option>
                                    {
                                        times.map((item) => {
                                            return <option key={`to_${item}`} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <ReactQuill theme="snow" value={richText} onChange={setRichText} modules={{
                                toolbar: {
                                    container: [
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                                        [{ 'size': ['small', false, 'large', 'huge'] }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        [{ 'color': [] }, { 'background': [] }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ],
                                    formats: ['bold', 'italic', 'underline', 'strike', 'link', 'image', 'video', 'header', 'code-block', 'blockquote', 'list', 'align', 'background', 'color', 'indent', 'script']
                                },
                                clipboard: { matchVisual: true }
                            }} style={{ height: 150 }} />
                        </div>
                    </div>


                    <div style={{ marginTop: 150 }}>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-primary btn-block" type="submit" disabled={loading || title == ''}>{loading ? resources.loading : id != 0 ? resources.addClassPageUpdateButton : resources.addClassPageSaveButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>



            {
                loadingTable
                    ? <Loading />
                    : !loadingTable && errorTable
                        ? <ErrorMessage errorMessage={resources.addClassPageErrorTableMessage} />
                        : generateTable()
            }
        </div>
    )
}

export default SaveFitnessClass;