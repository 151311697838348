import { useState } from "react";
import { resources } from "../../helpers/Resource";
import { saveBlockedDaysApi, saveCalendarPrimarySettingApi } from "../../util/api";
import ErrorMessage from "../ErrorMessage";
import Loading from "../Loading";
import SuccessMessage from "../SuccessMessage";



const SpecificCalendar = ({trainerId}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('startDate', beginDate);
            formData.append('endDate', endDate);
            formData.append('trainerId', trainerId);
            const response = await saveBlockedDaysApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setBeginDate('');
                setEndDate('');
                setSuccess(true);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
            setSuccess(false);
        }
    }

    const handleBeginDateChange = (event) => {
        setBeginDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.calendarPageSpecificCalendarErrorMesssage} />
    }

    return (
        <div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.calendarPageSpecificCalendarSuccessMessage} />
                    : null
            }

            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPageSpecificCalenderStartDate}</label>
                                <input type="date" className="form-control" id="start" value={beginDate} onChange={handleBeginDateChange} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.calendarPageSpecificCalenderEndDate}</label>
                                <input type="date" className="form-control" id="end" value={endDate} onChange={handleEndDateChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary btn-block" type="submit" style={{opacity: (!loading && (beginDate == '' || endDate == '')) ? 0.5 : 1}} disabled={loading || (beginDate == '' || endDate == '')}>{loading ? resources.loading : resources.calendarPageSpecificSaveButton}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SpecificCalendar;