import React, { useRef, useState } from 'react';
import { resources } from '../helpers/Resource';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createLessonApi } from '../util/api';
import Loading from '../components/Loading';
import ErrorMessage from '../components/ErrorMessage';
import SuccessMessage from '../components/SuccessMessage';

const CreateLesson = () => {
    const [lessonTitle, setLessonTitle] = useState('');
    const filesInput = useRef(null);
    const [richText, setRichText] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            for (let i = 0; i < filesInput.current.files.length; i++) {
                formData.append('files', filesInput.current.files[i]);
            }
            formData.append('lessonTitle', lessonTitle);
            formData.append('richText', richText);
            const response = await createLessonApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setLessonTitle('');
                setRichText('');
                filesInput.value = null;
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const lessonTitleChange = (event) => {
        setLessonTitle(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.createLessonErrorMessage} />
    }


    return (
        <div style={{ margin: 20 }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{resources.createLessonTitle}</h1>
            </div>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.createLessonSuccessMessage} />
                    : null
            }
            <form onSubmit={handleSubmit}>
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">{resources.createLessonLessonTitle}</label>
                                <input type="text" className="form-control" id="createLessonLessonTitle" value={lessonTitle} onChange={lessonTitleChange} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="input-group mb-3">
                                <label className="input-group-text">Upload</label>
                                <input type="file" multiple className="form-control" id="createLessonFiles" ref={filesInput} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <ReactQuill theme="snow" value={richText} onChange={setRichText} modules={{
                                toolbar: {
                                    container: [
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                                        [{ 'size': ['small', false, 'large', 'huge'] }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        [{ 'color': [] }, { 'background': [] }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ],
                                    formats: ['bold', 'italic', 'underline', 'strike', 'link', 'image', 'video', 'header', 'code-block', 'blockquote', 'list', 'align', 'background', 'color', 'indent', 'script']
                                },
                                clipboard: { matchVisual: true }
                            }} style={{ height: 150 }} />
                        </div>
                    </div>

                    <div style={{ marginTop: 135 }}>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-primary btn-block" type="submit" disabled={loading || lessonTitle == ''}>{loading ? resources.loading : resources.createLessonSaveButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateLesson;
