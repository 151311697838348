
import React, { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { getUserDetailApi } from '../util/api';
import { resources } from '../helpers/Resource';
import { useLocation } from 'react-router-dom';
import UserBasicInfo from '../components/UserBasicInfo';
import UserDetailContext from '../store/userdetail-context';
import UserWorkout from '../components/UserWorkout';
import UserDiet from '../components/UserDiet';
import UserTakenLesson from '../components/UserTakenLesson';
import UserMeasurementInformation from '../components/UserMeasurementInformation';
import UserAssignVideo from '../components/UserAssignVideo';
import Loading from '../components/Loading';
import UserReport from '../components/UserReport';
import UserBioimpedance from '../components/UserBioimpedance';
import UserPushNotification from '../components/UserPushNotification';

const UserDetail = () => {
    const userDtlCtx = useContext(UserDetailContext);
    const { state } = useLocation();
    const id = state?.id;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState('basic-info');

    useEffect(() => {
        getUserDetail();
    }, []);

    const getUserDetail = async () => {
        setError(false);
        try {
            const formData = new FormData();
            formData.append('userID', id);
            const response = await getUserDetailApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                userDtlCtx.addUser({ ...response.data });
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    const showDetail = (inf) => {
        setInfo(inf);
    }

    return (
        <div style={{ margin: 20 }}>
            {
                error && !loading ?
                    <div className="alert alert-danger" role="alert">
                        {resources.createLessonErrorMessage}
                    </div>
                    : null
            }
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{resources.userDetailTitle}</h1>
            </div>

            <div className="container text-center">
                <div className="row">
                    <div className="col">
                        <div onClick={() => showDetail('basic-info')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'basic-info' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'basic-info' ? 'white' : '#4e73df' }}>{resources.userDetailBasicInfo}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-workout')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-workout' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-workout' ? 'white' : '#4e73df' }}>{resources.userDetailUserWorkout}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-diet')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-diet' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-diet' ? 'white' : '#4e73df' }}>{resources.userDetailUserDiet}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-measurement-information')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-measurement-information' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-measurement-information' ? 'white' : '#4e73df' }}>{resources.userDetailUserMeasurementInformation}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-assign-video')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-assign-video' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-assign-video' ? 'white' : '#4e73df' }}>{resources.userDetailUserAssignVideo}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-report')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-report' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-report' ? 'white' : '#4e73df' }}>{resources.userDetailUserReport}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-bioimpedance')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-bioimpedance' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-bioimpedance' ? 'white' : '#4e73df' }}>{resources.userDetailUserBioimpedance}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => showDetail('user-push-notification')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-push-notification' ? '#4e73df' : 'white', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-push-notification' ? 'white' : '#4e73df' }}>{resources.userDetailUserNofication}</span>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div onClick={() => showDetail('user-taken-lesson')} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: info === 'user-taken-lesson' ? '#4e73df' : 'white' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 15, color: info === 'user-taken-lesson' ? 'white' : '#4e73df' }}>{resources.userDetailUserTakenLesson}</span>
                        </div>
                    </div> */}
                </div>
            </div>


            {
                info === 'basic-info'
                    ? <UserBasicInfo user={userDtlCtx.user} />
                    : info === 'user-workout'
                        ? <UserWorkout />
                        : info === 'user-diet'
                            ? <UserDiet />
                            : info === 'user-measurement-information'
                                ? <UserMeasurementInformation />
                                : info === 'user-assign-video'
                                    ? <UserAssignVideo />
                                    : info === 'user-report'
                                        ? <UserReport user={userDtlCtx.user} />
                                        : info === 'user-bioimpedance'
                                            ? <UserBioimpedance user={userDtlCtx.user} />
                                            : info === 'user-push-notification'
                                                ? <UserPushNotification user={userDtlCtx.user} />
                                                : null
                // : info === 'user-taken-lesson'
                //     ? <UserTakenLesson />
                //     : null

            }
        </div>
    )

}

export default UserDetail;