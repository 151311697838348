import { Fragment, useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';

import MainNavigation from './MainNavigation';

const Layout = (props) => {
  const authCtx = useContext(AuthContext);


  const [menuHiddenStyle, setMenuHiddenStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
  const changeMenuHidden = () => {
    if (menuHiddenStyle == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setMenuHiddenStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    } else {
      setMenuHiddenStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  }

  const [userSettingsStyle, setUserSettingsStyle] = useState("nav-item dropdown no-arrow");
  const [userSettingsGrowIn, setUserSettingsGrowIn] = useState("dropdown-menu dropdown-menu-right shadow animated--grow-in");
  const [userSettingsBoolean, setUserSettingsBoolean] = useState(false);
  const changeUserSettingsHidden = () => {
    if (userSettingsStyle == "nav-item dropdown no-arrow") {
      setUserSettingsStyle("nav-item dropdown no-arrow show");
      setUserSettingsGrowIn("dropdown-menu dropdown-menu-right shadow animated--grow-in show");
      setUserSettingsBoolean(true);
    } else {
      setUserSettingsStyle("nav-item dropdown no-arrow");
      setUserSettingsGrowIn("dropdown-menu dropdown-menu-right shadow animated--grow-in");
      setUserSettingsBoolean(false);
    }
  }

  const [username, setUsername] = useState("admin");

  const menuClick = (menu) => {
    //console.log(`Clicked to ${menu}`)
  }

  const logout = () => {

  }


  if (!authCtx.isLoggedIn) {
    return <Fragment>
      {props.children}
    </Fragment>
  }


  return (
    <Fragment>
      <div id="wrapper">
        <ul className={menuHiddenStyle} id="accordionSidebar">
          <div className="sidebar-brand d-flex align-items-center justify-content-center">
            <div className="sidebar-brand-text mx-3">Admin Panel</div>
          </div>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/home">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <hr className="sidebar-divider my-0" />
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/create-lesson">
              <i className="fas fa-fw fa-pen"></i>
              <span>Create Lesson</span>
            </a>
          </li> */}
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/lessons">
              <i className="fas fa-fw fa-file"></i>
              <span>Lessons</span>
            </a>
          </li> */}
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/users">
              <i className="fas fa-fw fa-users"></i>
              <span>Users</span>
            </a>
          </li>
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/trainer">
              <i className="fas fa-fw fa-user"></i>
              <span>Trainer</span>
            </a>
          </li> */}
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/room">
              <i className="fas fa-fw fa-home"></i>
              <span>Room</span>
            </a>
          </li> */}
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/videolesson">
              <i className="fas fa-fw fa-video"></i>
              <span>Video Lesson</span>
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/whatsapp-integration">
              <i className="fas fa-fw fa-phone"></i>
              <span>Whatsapp Integration</span>
            </a>
          </li>
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/savefitnessclass">
              <i className="fas fa-fw fa-school"></i>
              <span>Save Fitness Class</span>
            </a>
          </li> */}
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/promo">
            <i className="fas fa-fw fa-newspaper"></i>
              <span>Promo</span>
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/news">
            <i className="fas fa-fw fa-newspaper"></i>
              <span>News</span>
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/notification">
            <i className="fas fa-fw fa-bell"></i>
              <span>Notifica</span>
            </a>
          </li>
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/calendar">
              <i className="fas fa-fw fa-clock"></i>
              <span>Calendar</span>
            </a>
          </li> */}
          {/* <li className="nav-item active">
            <a className="nav-link" style={{ cursor: 'pointer' }} href="/logs">
              <i className="fas fa-fw fa-bug"></i>
              <span>Error Logs</span>
            </a>
          </li> */}

          <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeMenuHidden}></button>
          </div>

        </ul>

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={changeMenuHidden}>
                <i className="fa fa-bars"></i>
              </button>
              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                <li className="nav-item dropdown no-arrow">
                  <div className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded={userSettingsBoolean} onClick={changeUserSettingsHidden}>
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{username}</span>
                    <img className="img-profile rounded-circle" src="img/undraw_profile.svg" />
                  </div>
                  <div className={userSettingsGrowIn} aria-labelledby="userDropdown">
                    <a className="dropdown-item" href="logout">
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {props.children}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
