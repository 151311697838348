
const resources = {

    loading: 'Loading',
    search: 'Search',
    errorMessage: 'Something went wrong',
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    makePassive: 'Make Passive',
    makeActive: 'Make Active',
    title:'Titolo',
    message: 'messaggio',
    add: 'Add',
    addPdf: 'Add Pdf',


    addUserMeasurementInfoNameInput: 'Nome',
    addUserMeasurementInfoSurnameInput: 'Cognome',
    addUserMeasurementInfoBirthdayInput: 'Data di Nascita',
    addUserMeasurementInfoWeightInput: 'Peso (kg)',
    addUserMeasurementInfoHeightInput: 'Altezza (cm)',
    addUserMeasurementInfoPatologieInput: 'Patologie',
    addUserMeasurementInfoMedicationsSupplementsUseInput: 'Farmaci/integratori in uso',
    addUserMeasurementInfoJobInput: 'Occupazione',
    addUserMeasurementInfoHoursOfSleepInput: 'Ore di sonno',
    addUserMeasurementInfoStandingHoursInput: 'Ore in piedi',
    addUserMeasurementInfoSittingHoursInput: 'Ore seduto',
    addUserMeasurementInfoPrimaryGoalInput: 'Obiettivo primario',
    addUserMeasurementInfoWeeklyTrainingFrequencyInput: 'Frequenza allenamenti settimanali',
    addUserMeasurementInfoAvailableForTrainingInput: "Tempo disponibile per l'allenamento",
    addUserMeasurementInfoMailInput: 'Mail',
    addUserMeasurementInfoWristInput: 'Polso',
    addUserMeasurementInfoWaistInput: 'Vita',
    addUserMeasurementInfoFlanksInput: 'Fianchi',
    addUserMeasurementInfoMediumThighInput: 'Coscia Media',
    addUserMeasurementInfoBicepsInput: 'Bicipite',
    addUserMeasurementInfoCalfInput: 'Polpaccio',
    addUserMeasurementInfoCurrentDietInput: 'Dieta attuale giornaliera',


    pdfName: 'Titolo pdf',
    week: 'Week',
    send: 'Inviare',

    homepageUserCount: 'User Count',
    homepageLessonCount: 'Lesson Count',
    homepageDailyLogCount: 'Daily Log Count',
    homepageLessons: 'Lessons',
    homepageMeetings: 'Meetings',
    homepageMeetingLink: 'Meeting Link',
    homepageErrorMessage: 'Something went wrong',
    homepageTrainerCount: 'Trainer Count',



    createLessonTitle: 'Create Lesson',
    createLessonLessonTitle: 'Lesson Title',
    createLessonSaveButton: 'Save',
    createLessonSuccessMessage: 'Lesson was created succesfully!',
    createLessonErrorMessage: 'Something went wrong with server',


    lessonsTitle: 'Lessons',
    lessonsErrorMessage: 'Something went wrong with server',
    lessonsSuccessMessage: 'Lesson was deleted succesfully!',
    lessonsShowRichText: 'Show Rich Text',
    lessonsEdit: 'Edit',
    lessonsDelete: 'Delete',
    lessonsPageLessonTitle: 'Lesson Title',
    lessonsPageCreatedDate: 'Created Date',
    lessonsPageRichText: 'Rich Text',
    lessonsPageFiles: 'Files',
    lessonsPageEdit: 'Edit',
    lessonsPageDelete: 'Delete',
    lessonsPageUserCount: 'Users',
    lessonsPageUserFullName: 'Full Name',
    lessonsPageUserUsername: 'Username',
    lessonsPageUserEmail: 'E-mail',
    lessonsPageUserDetail: 'User Detail',

    editLessonSelectFiles: 'These files that the files of the lesson. Please select files that you want to delete',
    editLessonAddNewFile: 'If you want to add new file, please add new file',
    editLessonSuccessMessage: 'Lesson was updated succesfully!',
    editLessonUpdateButton: 'Update',


    logsErrorMessage: 'Something went wrong with server',
    logsShowLogDetail: 'Show Log Detail',


    usersShowDetail: 'Show Detail',
    usersPageThName: 'Name',
    usersPageThSurname: 'Surname',
    usersPageThUsername: 'Username',
    usersPageThEmail: 'Email',
    usersPageThMobileNumber: 'Mobile Number',
    usersPageThDetail: 'Detail',


    userDetailTitle: 'User Detail',
    userDetailBasicInfo: 'Basic Info',
    userDetailShowLessons: 'Show Lessons',
    userDetailAssingLesson: 'Assign Lesson',
    userDetailShowReport: 'Show Report',
    userDetailSaveMeasurement: 'Save Measurement',
    userDetailUserWorkout: 'User Workout',
    userDetailUserDiet: 'Diet',
    userDetailUserMeasurementInformation: 'User Measurement',
    userDetailUserAssignVideo: 'Assign Video',
    userDetailUserReport: 'Report',
    userDetailUserBioimpedance: 'Bioimpedance Analysis',
    userDetailUserNofication: 'Noficazione',
    userDetailUserTakenLesson: 'User Taken Lesson',
    userDetailWeight: 'Weight (kg)',
    userDetailHeight: 'Height (cm)',
    userDetailShoulder: 'Shoulder',
    userDetailChest: 'Chest',
    userDetailBicep: 'Bicep',
    userDetailForearm: 'Forearm',
    userDetailWaist: 'Waist',
    userDetailKnee: 'Knee',
    userDetailHip: 'Hip',
    userDetailThigh: 'Thigh',
    userDetailCalfMuscle: 'CalfMuscle',
    userDetailBMI: 'BMI',
    userDetailSaveButton: 'Save',
    userDetailSuccessMessage: 'Measurements wwe saved succesfully!',
    userDetailErrorMessage: 'Something went wrong with server',
    userDetailNoDisplayDataForReport: 'There is no data to show chart yet.',
    userDetailAssignLessonErrorMessage: 'Something went wrong with server',
    userDetailSelectLesson: 'Select Lesson',
    userDetailIsLimitless: 'It is limitless lesson',
    userDetailSaveLessonButton: 'Save Lesson',
    userDetailAssingLessonSuccessMessage: 'The lesson was assigned the user successfully.',
    userDetailUsersLessonErrorMessage: 'Something went wrong with server',
    userDetailDeleteLesson: 'Delete Lesson from the user',
    userDetailReportErrorMessage: 'Something went wrong with server',
    userDetailworkoutPDFHistoryTitle: 'PDF History',
    userDetailworkoutTitle: 'Upload Workout PDF',
    userDetailSaveWorkoutSuccessMessage: 'Workout was created succesfully!',
    userDetailworkoutTableCreatedDate: 'Created Date',
    userDetailworkoutTableFile: 'File',
    userDetailworkoutPrograms: 'Programs',
    userDetailworkoutSaveButton: 'Save',
    userDetaildietPDFHistoryTitle: 'PDF History',
    userDetaildietTitle: 'Upload Diet PDF',
    userDetailSaveDietSuccessMessage: 'Diet was created succesfully!',
    userDetaildietTableCreatedDate: 'Created Date',
    userDetaildietTableFile: 'File',
    userDetaildietSaveButton: 'Save',
    userDetailUserBasicInfoTitle: 'Title',
    userDetailUserBasicInfoInfo: 'Info',
    userDetailUserBasicInfoName: 'Name',
    userDetailUserBasicInfoSurname: 'Surname',
    userDetailUserBasicInfoNameUsername: 'Username',
    userDetailUserBasicInfoNameEmail: 'E-mail',
    userDetailUserBasicInfoNameMobileNumber: 'Mobile Number',
    userDetailUserBasicInfoNameBirthday: 'Birthday',
    userDetailUserBasicInfoHeight: 'Height',
    userDetailUserMeasurementPageTitle: 'User Measurement Files',
    userDetailAssignVideoAssigned: 'Assigned',
    userDetailAssignVideoAssignedDate: 'Assigned Date',
    userDetailAssignVideoAssignedPerson: 'Assigned Person',
    userDetailAssignVideoVideoName: 'Assigned Video Name',
    userDetailAssignVideoVideo: 'Video',
    userDetailAssignNoVideo: 'There is no video to assign yet',
    userDetailPageSuccessMessage: 'Transaction done succesfully!',
    userDetailPageUsersProgress: 'Users Progress',
    userDetailPushNotiMessage: 'Push notification was sent successfully',
    userPushNotiTitle: 'Notifica',
    userUserMeasurementsDeleteMessage: 'Record was deleted succesfully!',


    userBioimpedanceAnalysisTitle: 'Bioimpedance Analysis',
    userBioimpedanceAnalysisSuccessMessage: 'Bioimpedance Analysis was created succesfully!',
    userBioimpedancePdf: 'PDF',
    userBioimpedanceCreatedDate: 'Created Date',
    userBioimpedanceDelete: 'Delete',
    userBioimpedanceTitle: 'Bioimpedance Analysis',
    userBioimpedanceAnalysisSuccessDeleteMessage: 'Bioimpedance Analysis was deleted succesfully!',


    promoScreenCreateTitle: 'Create Promo',
    promoScreenTitle: 'Promo',
    promoScreenPromosTitle: 'Title',
    promoScreenIsActive: 'Is Active',
    promoScreenUrl: 'Url',
    promoScreenDelete: 'Delete',
    promoScreenEdit: 'Edit',
    promoScreenSuccessMessage: 'Transaction is succesfully',
    promoScreenNoPromoMessage: 'There is no Promo',
    promoScreenPromoUrl: 'Promo Url',


    newsScreenCreateTitle: 'Create News',
    newsScreenTitle: 'News',
    newsScreenNewsTitle: 'Title',
    newsScreenIsActive: 'Is Active',
    newsScreenUrl: 'Url',
    newsScreenDelete: 'Delete',
    newsScreenEdit: 'Edit',
    newsScreenSuccessMessage: 'Transaction is succesfully',
    newsScreenNoNewsMessage: 'There is no News',
    newsScreenNewsUrl: 'News Url',

    videoLessonVideos: 'Video Lessons',
    videoLessonPageTitle: 'Create Video Lesson',
    videoLessonPageVideoName: 'Video Name',
    videoLessonPageVideoURL: 'Video Id',
    videoLessonPageThCreatedDate: 'Created Date',
    videoLessonPageThVideoName: 'Video Name',
    videoLessonPageThAssignedFullName: 'Assigned Full Name',
    videoLessonPageThVideo: 'Video',
    videoLessonPageThDelete: 'Delete',
    videoLessonPageSuccessMessage: 'Transaction done succesfully!',


    trainerPageFullName: 'Full Name',
    trainerPageTitle: 'Job Title',
    trainerPageSaveButton: 'Save',
    trainerPageEditButton: 'Edit Trainer',
    trainerPageDeleteButton: 'Delete Trainer',
    trainerPageErrorMessage: 'Something went wrong with server',
    trainerPageCreateTrainer: 'Create Trainer',
    trainerPageTrainers: 'Trainers',
    trainerPageThFullName: 'Full name',
    trainerPageThTitle: 'Title',
    trainerPageThAvatar: 'Avatar',
    trainerPageThEdit: 'Edit',
    trainerPageThDelete: 'Delete',
    trainerPageNoData: 'No trainer yet',


    whatsappIntegrationPageTitle: 'Whatsapp Integration',
    whatsappIntegrationPhoneNumber: 'Phone Number',
    whatsappIntegrationPhoneNumberPlaceholder: 'For the number +90 555 111 22 33 please use 905551112233',
    whatsappIntegrationPageGreetingMessage: 'Greeting Message',
    whatsappIntegrationPageSaveButton: 'Save',
    whatsappIntegrationPageSuccessMessage: 'Data was saved succesfully!',
    whatsappIntegrationPageErrorMessage: 'Something went wrong with server',


    roomPageRoomName: 'Room Name',
    roomPageSaveButton: 'Save',
    roomPageEditButton: 'Edit Room',
    roomPageDeleteButton: 'Delete Room',
    roomPageErrorMessage: 'Something went wrong with server',
    roomPageCreateRoomTitle: 'Create Room',
    roomPageRoomsTitle: 'Rooms',
    roomPageThRoomName: 'Room Name',
    roomPageThEdit: 'Edit',
    roomPageThDelete: 'Delete',

    frontImage: 'Front Image',
    backImage: 'Back Image',
    sideImage: 'Side Image',



    addClassPageHeaderTitle: 'Add new class',
    addClassPageCategory: 'Select Category',
    addClassPageTrainer: 'Select Trainer',
    addClassPageDay: 'Day',
    addClassPageFrom: 'From',
    addClassPageTo: 'To',
    addClassPageDescription: 'Description',
    addClassPageSaveButton: 'Save',
    addClassPageUpdateButton: 'Update',
    addClassPageErrorMessage: 'Something went wrong with server',
    addClassPageTitle: 'Title',
    addClassPageSuccessMessage: 'Class was created successfully',
    addClassPageUpdateSuccessMessage: 'Class was updated successfully',
    addClassPageErrorTableMessage: 'Table could not loaded',
    addClassPageMonday: 'Monday',
    addClassPageTuesday: 'Tuesday',
    addClassPageWednesday: 'Wednesday',
    addClassPageThursday: 'Thursday',
    addClassPageFriday: 'Friday',
    addClassPageSaturday: 'Saturday',
    addClassPageSunday: 'Sunday',
    addClassPageHideEmptyRows: 'Hide Empty Rows',
    addClassPageShowEmptyRows: 'Show Also Empty Hour Rows',


    calendarPageErrorMessage: 'Something went wrong with server',
    calendarPageHeaderTitle: 'Calendar Settings',
    calendarPageSuccessMessage: 'Calendar settings were saved successfully',
    calendarPageSelectSettingType: 'Select Calendar Setting Type',
    calendarPagePrimarySettingStartTime: 'Start Time',
    calendarPagePrimarySettingEndTime: 'End Time',
    calendarPagePrimarySettingMonday: 'Monday',
    calendarPagePrimarySettingTuesday: 'Tuesday',
    calendarPagePrimarySettingWednesday: 'Wednesday',
    calendarPagePrimarySettingThursday: 'Thursday',
    calendarPagePrimarySettingFriday: 'Friday',
    calendarPagePrimarySettingSaturday: 'Saturday',
    calendarPagePrimarySettingSunday: 'Sunday',
    calendarPagePrimarySettingDays: 'Days',
    calendarPagePrimarySettingUpdateButton: 'Update',
    calendarPageSpecificCalendarErrorMesssage: 'Something went wrong with server',
    calendarPageSpecificCalendarSuccessMessage: 'Specific date/dates were saved successfully',
    calendarPageSpecificCalenderStartDate: 'Start Date',
    calendarPageSpecificCalenderEndDate: 'End Date',
    calendarPageSpecificSaveButton: 'Save',
    calendarPageSpecificHourErrorMesssage: 'Something went wrong with server',
    calendarPageSpecificHourSuccessMessage: 'Specific hours were saved successfully',
    calendarPageSpecificHourSaveButton: 'Save',
    calendarPageSpecificHourDate: 'Date',
    calendarPageDeleteBlockedDateDetail: 'Delete',
    calendarPageDeleteBlockedSuccessMessage: 'The date were deleted successfully',
    calendarPageTrainer: 'Select Trainer',



    logsPageThCreatedDate: 'Created Date',
    logsPageThLog: 'Log',
    logsPageNoData: 'There is no log yet',

    usertakenPageTitle: 'User Taken Lesson',
    usertakenPageLessonTitle: 'Taken Lesson Count',
    usertakenPageRemainingLessonTitle: 'Remaining Lesson Count',
    usertakenPageRemainingTakenDateTitle: 'Taken Date',
    usertakenPageSuccessMessage: 'Transaction done succesfully!',
    usertakenPageTakenDate: 'Taken Date',
    usertakenPageAction: 'Action',

}

export { resources }