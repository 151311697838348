
import React, { useContext, useEffect, useState } from 'react';
import { resources } from '../helpers/Resource';
import Loading from '../components/Loading';
import { getNewsApi, makePassiveNewsApi, makeActiveNewsApi, saveNewsApi } from '../util/api';
import SuccessMessage from '../components/SuccessMessage';

const NewsScreen = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [success, setSuccess] = useState(false);
    const [id, setId] = useState(0);
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        getNews();
    }, []);

    const getNews = async () => {
        setLoading(true);
        setError(false);

        try {
            const response = await getNewsApi();
            if (response.status === 200) {
                setData(response.data.news);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }


    const editNews = async (id, title, url) => {
        setTitle(title);
        setUrl(url);
        setId(id);
    }

    const makePassive = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            const response = await makePassiveNewsApi(formData);
            if (response.status === 200) {
                if (response.data.result == true) {
                    setSuccess(true);
                    setError(false);
                    setData(response.data.news);
                    setTitle('');
                    setUrl('');
                    setLoading(false);
                }
                else {
                    setSuccess(false);
                    setError(true);
                    setData(response.data.news);
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                setError(true);
                setData([]);
                setSuccess(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const makeActive = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            const response = await makeActiveNewsApi(formData);
            if (response.status === 200) {
                if (response.data.result == true) {
                    setSuccess(true);
                    setError(false);
                    setData(response.data.news);
                    setTitle('');
                    setUrl('');
                    setLoading(false);
                }
                else {
                    setSuccess(false);
                    setError(true);
                    setData(response.data.news);
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                setError(true);
                setData([]);
                setSuccess(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('url', url);
            formData.append('id', id);
            const response = await saveNewsApi(formData);
            if (response.status === 200) {
                if (response.data.result == true) {
                    setSuccess(true);
                    setError(false);
                    setData(response.data.news);
                    setTitle('');
                    setUrl('');
                    setLoading(false);
                }
                else {
                    setSuccess(false);
                    setError(true);
                    setData(response.data.news);
                    setLoading(false);
                }
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const urlChange = (event) => {
        setUrl(event.target.value);
    }

    const titleChange = (event) => {
        setTitle(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ margin: 20 }}>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4>{resources.newsScreenCreateTitle}</h4>
            </div>

            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.newsScreenSuccessMessage} />
                    : null
            }



            <div className="shadow p-3 mb-5 bg-white rounded">
                <div style={{ margin: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.newsScreenTitle}</label>
                                        <input type="text" className="form-control" value={title} onChange={titleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.newsScreenUrl}</label>
                                        <input type="text" className="form-control" value={url} onChange={urlChange} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-primary btn-block" type="submit" disabled={loading || url == '' || title == ''}>{loading ? resources.loading : resources.save}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            {
                !loading && data.length == 0
                    ? <div className="alert alert-info" role="alert" style={{ margin: 30 }}>
                        {resources.newsScreenNoNewsMessage}
                    </div>
                    : null
            }

            {
                data.length > 0
                    ?
                    <div>
                        <h4>{resources.newsScreenTitle}</h4>
                        <div className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>Count per page:</span>
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>{resources.newsScreenTitle}</th>
                                            <th>{resources.newsScreenUrl}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.newsScreenIsActive}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.newsScreenEdit}</th>
                                            <th style={{ textAlign: 'center' }}>{resources.newsScreenDelete}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.title}</td>
                                                <td><a href={item.url} target='_blank'>{resources.newsScreenNewsUrl}</a></td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item.isActive ? <div style={{ height: 15, width: 15, borderRadius: 25, backgroundColor: 'green' }}></div> : <div style={{ height: 15, width: 15, borderRadius: 25, backgroundColor: 'red' }}></div>}</td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                    <button type="button" className="btn btn-info btn-sm" onClick={() => editNews(item.id, item.title, item.url)}>{resources.edit}</button>
                                                </td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                    {
                                                        item.isActive
                                                            ? <button type="button" className="btn btn-danger btn-sm" onClick={() => makePassive(item.id)}>{resources.makePassive}</button>
                                                            : <button type="button" className="btn btn-success btn-sm" onClick={() => makeActive(item.id)}>{resources.makeActive}</button>
                                                    }

                                                </td>
                                                {/* <td><i key={`td_${item.id}`} className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => downloadFile(item.workoutPDFPath)}></i></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                    Previous
                                </button>
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                    Next
                                </button>
                                <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )

}

export default NewsScreen;