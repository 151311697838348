import React, { useEffect, useState, useRef, useContext } from "react";
import UserDetailContext from '../store/userdetail-context';
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import api_url, { saveWorkoutToUserPDFApi, getWorkoutUserFilesApi, deleteUWorDietApi } from '../util/api';

const UserWorkout = () => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalStyle, setModalStyle] = useState({});
    const [currentProgram, setCurrentProgram] = useState({});

    const [pdfName, setPDFName] = useState('');

    const pdfNameChange = (event) => {
        setPDFName(event.target.value);
    }

    const filesInput = useRef(null);
    const userDtlCtx = useContext(UserDetailContext);

    const filteredData = data;
    // const filteredData = data.filter((item) =>
    //    item.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleUWButtonClick = (id) => {
        deleteUW(id);
    };

    const deleteUW = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('userId', userDtlCtx.user.id);

            const response = await deleteUWorDietApi(formData);
            if (response.status === 200) {
                setData(response.data.listData);
                setData2(response.data.workoutPrograms);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleButtonClick = (createdDate) => {
        setModalStyle({ display: 'block' });
        setCurrentProgram(data2.filter(x => x.workoutTime == createdDate))
    };


    useEffect(() => {
        getWorkoutUserFiles();
    }, []);

    const getWorkoutUserFiles = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            setLoading(false);
            const formData = new FormData();
            formData.append('userID', userDtlCtx.user.id);
            const response = await getWorkoutUserFilesApi(formData);

            if (response.status === 200) {
                setData(response.data.listData);
                setData2(response.data.workoutPrograms);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (pdfName == null || pdfName == '') {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('file', filesInput.current.files[0]);
            formData.append('userID', userDtlCtx.user.id);
            formData.append('pdfname', pdfName);
            const response = await saveWorkoutToUserPDFApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setData(response.data);
                filesInput.value = null;
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleOutsideClick = (event) => {
        if (event.target === event.currentTarget) {
            setModalStyle({ display: 'none' });
        }
    };


    return (
        <div style={{ margin: 20 }}>

            <div className="modal" style={modalStyle} onClick={handleOutsideClick}>
                <div className="modal-dialog" style={{ maxWidth: 'none', width: '90%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{resources.userDetailworkoutPrograms}</h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleOutsideClick}>Close</button>
                        </div>
                        <div className="modal-body">

                            {currentProgram != null && currentProgram.length > 0
                                ? <div>
                                    {currentProgram.map((item, index) => {
                                        return <div key={item.exerciseName + index}>
                                            <h1>{item.exerciseName}</h1>
                                            <ul>
                                                <li>{resources.week} 1 : {item.week1}</li>
                                                <li>{resources.week} 2 : {item.week2}</li>
                                                <li>{resources.week} 3 : {item.week3}</li>
                                                <li>{resources.week} 4 : {item.week4}</li>
                                                <li>{resources.week} 5 : {item.week5}</li>
                                                <li>{resources.week} 6 : {item.week6}</li>
                                                <li>{resources.week} 7 : {item.week7}</li>
                                                <li>{resources.week} 8 : {item.week8}</li>
                                            </ul>
                                        </div>
                                    })}
                                </div>
                                : <h2>No data</h2>}
                        </div>
                    </div>
                </div>
            </div>

            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.userDetailSaveWorkoutSuccessMessage} />
                    : null
            }

            {
                currentRows.length > 0
                    ?
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <h4>{resources.userDetailworkoutPDFHistoryTitle}</h4>
                    </div>
                    : null
            }
            {
                currentRows.length > 0
                    ? <div className="shadow p-3 mb-5 bg-white rounded">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                <span style={{ marginRight: 10 }}>Count per page:</span>
                                <div className="btn-group me-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{resources.userDetailworkoutTableCreatedDate}</th>
                                        <th>{resources.userDetailworkoutTableFile}</th>
                                        <th>{resources.pdfName}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailworkoutPrograms}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows && currentRows.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.createdDateString}</td>
                                            <td><a className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} href={item.workoutPDFPath} target="_blank" /></td>
                                            <td>{item.pdfName}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={() => handleButtonClick(item.createdDate)}
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    {resources.userDetailworkoutPrograms}
                                                </button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={() => handleUWButtonClick(item.id)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    {resources.delete}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                Previous
                            </button>
                            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                Next
                            </button>
                            <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                        </div>
                    </div>
                    : null
            }

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4>{resources.userDetailworkoutTitle}</h4>
            </div>

            <div className="shadow p-3 mb-5 bg-white rounded">
                <div style={{ margin: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text">Upload</label>
                                        <input type="file" accept="application/pdf" className="form-control" id="createLessonFiles" ref={filesInput} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.pdfName}</label>
                                        <input type="text" className="form-control" value={pdfName} onChange={pdfNameChange} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.userDetailworkoutSaveButton}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UserWorkout;