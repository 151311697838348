import React, { useEffect, useState, useRef, useContext } from "react";
import UserDetailContext from '../store/userdetail-context';
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import api_url, { saveDietToUserPDFApi, getDietUserFilesApi,deleteDietApi } from '../util/api';

const UserDiet = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pdfName, setPDFName] = useState('');

    const pdfNameChange = (event) => {
        setPDFName(event.target.value);
    }

    const filesInput = useRef(null);
    const userDtlCtx = useContext(UserDetailContext);

    const filteredData = data;
    // const filteredData = data.filter((item) =>
    //    item.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleDietButtonClick = (id) => {
        deleteUW(id);
    };

    const deleteUW = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('userId', userDtlCtx.user.id);

            const response = await deleteDietApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    useEffect(() => {
        getDietUserFiles();
    }, []);

    const getDietUserFiles = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            setLoading(false);
            const formData = new FormData();
            formData.append('userID', userDtlCtx.user.id);
            const response = await getDietUserFilesApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (pdfName == null || pdfName == '') {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

   
        try {
            const formData = new FormData();
            formData.append('file', filesInput.current.files[0]);
            formData.append('userID', userDtlCtx.user.id);
            formData.append('pdfname', pdfName);
            const response = await saveDietToUserPDFApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setData(response.data);
                filesInput.value = null;
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div style={{ margin: 20 }}>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.userDetailSaveDietSuccessMessage} />
                    : null
            }

            {
                currentRows.length > 0
                    ?
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <h4>{resources.userDetaildietPDFHistoryTitle}</h4>
                    </div>
                    : null
            }
            {
                currentRows.length > 0
                    ? <div className="shadow p-3 mb-5 bg-white rounded">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                <span style={{ marginRight: 10 }}>Count per page:</span>
                                <div className="btn-group me-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{resources.userDetaildietTableCreatedDate}</th>
                                        <th>{resources.pdfName}</th>
                                        <th>{resources.userDetaildietTableFile}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.createdDateString}</td>
                                            <td>{item.pdfName}</td>
                                            <td><a className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} href={item.dietPDFPath} target="_blank"  rel="noopener noreferrer"/></td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={() => handleDietButtonClick(item.id)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    {resources.delete}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                Previous
                            </button>
                            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                Next
                            </button>
                            <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                        </div>
                    </div>
                    : null
            }

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4>{resources.userDetaildietTitle}</h4>
            </div>

            <div className="shadow p-3 mb-5 bg-white rounded">
                <div style={{ margin: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text">Upload</label>
                                        <input type="file" accept="application/pdf" className="form-control" id="createLessonFiles" ref={filesInput} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text">PDF Name</label>
                                        <input type="text" className="form-control" value={pdfName} onChange={pdfNameChange} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.userDetaildietSaveButton}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
};

export default UserDiet;