import { Fragment, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Home from './pages/HomePage';
import PrivateRoute from './helpers/PrivateRoute';
import AuthContext from './store/auth-context';
import CreateLesson from './pages/CreateLesson';
import Lessons from './pages/Lessons';
import EditLesson from './pages/EditLesson';
import Logs from './pages/Logs';
import Users from './pages/Users';
import UserDetail from './pages/UserDetail';
import UserReport from './components/UserReport';
import AssignLesson from './components/AssingLesson';
import Logout from './components/Logout';
import Layout from './components/Layout/Layout';
import Trainer from './pages/Trainer';
import Room from './pages/Room';
import SaveFitnessClass from './pages/SaveFitnessClass';
import Calendar from './pages/Calendar';
import VideoLesson from './pages/VideoLesson';
import WhatsappIntegration from './pages/WhatsappIntegration';
import UserTakenLesson from './components/UserTakenLesson';
import PromoScreen from './pages/PromoScreen';
import NewsScreen from './pages/NewsScreen';
import NotificationScreen from './pages/NotificationScreen';

const App = () => {
  const authCtx = useContext(AuthContext);

  return (
    <Layout>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path='/' element={<PrivateRoute isLoggedIn={authCtx.isLoggedIn} />}>
              {/* <Route exact path='/' element={<Home />} > */}
              <Route exact path='/home' element={<Home />} />
              <Route exact path='' element={<Home />} />
            </Route>
            <Route exact path='/create-lesson' element={<CreateLesson />} />
            <Route exact path='/login' element={<Login />} />
            <Route path='/lessons' element={<Lessons />} />
            <Route path='/edit-lesson' element={<EditLesson />} />
            <Route path='/logs' element={<Logs />} />
            <Route path='/users' element={<Users />} />
            <Route path='/user-detail' element={<UserDetail />} />
            <Route path='/user-report' element={<UserReport />} />
            <Route path='/assign-lesson' element={<AssignLesson />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/trainer' element={<Trainer />} />
            <Route path='/room' element={<Room />} />
            <Route path='/savefitnessclass' element={<SaveFitnessClass />} />
            <Route path='/calendar' element={<Calendar />} />
            <Route path='/videolesson' element={<VideoLesson />} />
            <Route path='/whatsapp-integration' element={<WhatsappIntegration />} />
            <Route path='/user-taken-lesson' element={<UserTakenLesson />} />
            <Route path='/promo' element={<PromoScreen />} />
            <Route path='/news' element={<NewsScreen />} />
            <Route path='/notification' element={<NotificationScreen />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </Layout>

  );
};

export default App;
