import React, { useEffect, useState, useRef, useContext } from "react";
import UserDetailContext from '../store/userdetail-context';
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import api_url, { deleteUserMeasurementApi, getUserMeasurementInformationListApi, saveUserMeasurementInfo, saveUserMeasurementPdfInfo } from '../util/api';
import SuccessMessage from "./SuccessMessage";

const UserMeasurementInformation = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [success, setSuccess] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const filesInput = useRef(null);
    const image1Input = useRef(null);
    const image2Input = useRef(null);
    const image3Input = useRef(null);
    const [showAddContainer, setShowAddContainer] = useState(false);
    const [showAddPdfContainer, setShowAddPdfContainer] = useState(false);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthday, setBirthday] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [patologie, setPatologie] = useState('');
    const [medicationsSupplementsUse, setMedicationsSupplementsUse] = useState('');
    const [job, setJob] = useState('');
    const [hoursOfSleep, setHoursOfSleep] = useState('');
    const [standingHours, setStandingHours] = useState('');
    const [sittingHours, setSittingHours] = useState('');
    const [primaryGoal, setPrimaryGoal] = useState('');
    const [weeklyTrainingFrequency, setWeeklyTrainingFrequency] = useState('');
    const [availableForTraining, setAvailableForTraining] = useState('');
    const [mail, setMail] = useState('');
    const [wrist, setWrist] = useState('');
    const [waist, setWaist] = useState('');
    const [flanks, setFlanks] = useState('');
    const [mediumThigh, setMediumThigh] = useState('');
    const [biceps, setBiceps] = useState('');
    const [calf, setCalf] = useState('');
    const [currentDiet, setCurrentDiet] = useState('');


    const nameChange = (event) => {
        setName(event.target.value);
    }

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const userDtlCtx = useContext(UserDetailContext);

    const filteredData = data;
    // const filteredData = data.filter((item) =>
    //    item.lessonTitle.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        getUserMeasurementInformationList();
    }, []);

    const getUserMeasurementInformationList = async () => {
        setLoading(true);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('userID', userDtlCtx.user.id);
            const response = await getUserMeasurementInformationListApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
                setLoading(false);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleButtonClick = (id) => {
        deleteMea(id);
    };

    const deleteMea = async (id) => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('userId', userDtlCtx.user.id);

            const response = await deleteUserMeasurementApi(formData);
            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
                setError(false);
                setSuccessDelete(true);
            }
            else {
                setData([]);
                setLoading(false);
                setError(false);
                setSuccessDelete(false);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (birthday == null || birthday == "") {
            return;
        }

        if (image1Input == null || !image1Input.current.files[0]) {
            return;
        }

        if (image2Input == null || !image2Input.current.files[0]) {
            return;
        }

        if (image3Input == null || !image3Input.current.files[0]) {
            return;
        }



        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('photo1', image1Input.current.files[0]);
            formData.append('photo2', image2Input.current.files[0]);
            formData.append('photo3', image3Input.current.files[0]);
            formData.append('userId', userDtlCtx.user.id);
            formData.append('name', name);
            formData.append('surname', surname);
            formData.append('birthday', birthday);
            formData.append('weight', weight);
            formData.append('height', height);
            formData.append('patologie', patologie);
            formData.append('medicationsSupplementsUse', medicationsSupplementsUse);
            formData.append('job', job);
            formData.append('hoursOfSleep', hoursOfSleep);
            formData.append('standingHours', standingHours);
            formData.append('sittingHours', sittingHours);
            formData.append('primaryGoal', primaryGoal);
            formData.append('weeklyTrainingFrequency', weeklyTrainingFrequency);
            formData.append('availableForTraining', availableForTraining);
            formData.append('mail', mail);
            formData.append('wrist', wrist);
            formData.append('waist', waist);
            formData.append('flanks', flanks);
            formData.append('mediumThigh', mediumThigh);
            formData.append('biceps', biceps);
            formData.append('calf', calf);
            formData.append('currentDiet', currentDiet);

            const response = await saveUserMeasurementInfo(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setData(response.data);
                image1Input.value = null;
                image2Input.value = null;
                image3Input.value = null;
                showAddContainerHandle(false);
                showAddPdfContainerHandle(false);
                setName('');
                setSurname('');
                setBirthday('');
                setWeight('');
                setHeight('');
                setPatologie('');
                setMedicationsSupplementsUse('');
                setJob();
                setHoursOfSleep('');
                setSittingHours('');
                setPrimaryGoal('');
                setWeeklyTrainingFrequency('');
                setAvailableForTraining('');
                setMail('');
                setWrist('');
                setWaist('');
                setFlanks('');
                setMediumThigh('');
                setBiceps('');
                setCalf('');
                setCurrentDiet('');
                setStandingHours('');
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handlePdfSubmit = async (event) => {
        event.preventDefault();

        if (height == null || height == '') {
            return;
        }

        if (weight == null || weight == '') {
            return;
        }

        if (image1Input == null || !image1Input.current.files[0]) {
            return;
        }

        if (image2Input == null || !image2Input.current.files[0]) {
            return;
        }

        if (image3Input == null || !image3Input.current.files[0]) {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('file', filesInput.current.files[0]);
            formData.append('userID', userDtlCtx.user.id);
            formData.append('height', height);
            formData.append('weight', weight);
            formData.append('photo1', image1Input.current.files[0]);
            formData.append('photo2', image2Input.current.files[0]);
            formData.append('photo3', image3Input.current.files[0]);
            const response = await saveUserMeasurementPdfInfo(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setData(response.data);
                filesInput.value = null;
                image1Input.value = null;
                image2Input.value = null;
                image3Input.value = null;
                showAddContainerHandle(false);
                showAddPdfContainerHandle(false);

                
                setWeight('');
                setHeight('');
                
            }
            else {
                setData([]);
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }

    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    const showAddContainerHandle = (value) => {
        setShowAddContainer(value);
        setShowAddPdfContainer(false);
    }

    const showAddPdfContainerHandle = (value) => {
        setShowAddPdfContainer(value);
        setShowAddContainer(false);
    }
    return (
        <div style={{ margin: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                <div>
                    <h4>{resources.userDetailUserMeasurementPageTitle}</h4>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div onClick={() => showAddContainerHandle(!showAddContainer)} style={{ marginRight: 10, border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: '#4e73df', width: 100, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'bold', fontSize: 15, color: 'white' }}>{resources.add} +</span>
                    </div>
                    <div onClick={() => showAddPdfContainerHandle(!showAddPdfContainer)} style={{ border: '1px solid #4e73df', borderRadius: 3, cursor: 'pointer', backgroundColor: '#4e73df', width: 100, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'bold', fontSize: 15, color: 'white' }}>{resources.addPdf} +</span>
                    </div>
                </div>
            </div>


            {
                !loading && successDelete
                    ? <SuccessMessage successMessage={resources.userUserMeasurementsDeleteMessage} />
                    : null
            }

            {
                showAddContainer
                    ? <div className="shadow p-3 mb-5 bg-white rounded">
                        <div style={{ margin: 20 }}>
                            <form onSubmit={handleSubmit}>
                                <div className="container">

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoNameInput}</label>
                                                <input type="text" className="form-control" value={name} onChange={handleChange(setName)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoSurnameInput}</label>
                                                <input type="text" className="form-control" value={surname} onChange={handleChange(setSurname)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoBirthdayInput}</label>
                                                <input type="date" className="form-control" value={birthday} onChange={handleChange(setBirthday)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoWeightInput}</label>
                                                <input type="number" className="form-control" value={weight} onChange={handleChange(setWeight)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoHeightInput}</label>
                                                <input type="number" className="form-control" value={height} onChange={handleChange(setHeight)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoPatologieInput}</label>
                                                <input type="text" className="form-control" value={patologie} onChange={handleChange(setPatologie)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoMedicationsSupplementsUseInput}</label>
                                                <input type="text" className="form-control" value={medicationsSupplementsUse} onChange={handleChange(setMedicationsSupplementsUse)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoJobInput}</label>
                                                <input type="text" className="form-control" value={job} onChange={handleChange(setJob)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoHoursOfSleepInput}</label>
                                                <input type="number" className="form-control" value={hoursOfSleep} onChange={handleChange(setHoursOfSleep)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoStandingHoursInput}</label>
                                                <input type="number" className="form-control" value={standingHours} onChange={handleChange(setStandingHours)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoSittingHoursInput}</label>
                                                <input type="number" className="form-control" value={sittingHours} onChange={handleChange(setSittingHours)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoPrimaryGoalInput}</label>
                                                <input type="text" className="form-control" value={primaryGoal} onChange={handleChange(setPrimaryGoal)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoWeeklyTrainingFrequencyInput}</label>
                                                <input type="number" className="form-control" value={weeklyTrainingFrequency} onChange={handleChange(setWeeklyTrainingFrequency)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoAvailableForTrainingInput}</label>
                                                <input type="text" className="form-control" value={availableForTraining} onChange={handleChange(setAvailableForTraining)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoMailInput}</label>
                                                <input type="email" className="form-control" value={mail} onChange={handleChange(setMail)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoWristInput}</label>
                                                <input type="text" className="form-control" value={wrist} onChange={handleChange(setWrist)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoWaistInput}</label>
                                                <input type="text" className="form-control" value={waist} onChange={handleChange(setWaist)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoFlanksInput}</label>
                                                <input type="text" className="form-control" value={flanks} onChange={handleChange(setFlanks)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoMediumThighInput}</label>
                                                <input type="text" className="form-control" value={mediumThigh} onChange={handleChange(setMediumThigh)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoBicepsInput}</label>
                                                <input type="text" className="form-control" value={biceps} onChange={handleChange(setBiceps)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoCalfInput}</label>
                                                <input type="text" className="form-control" value={calf} onChange={handleChange(setCalf)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoCurrentDietInput}</label>
                                                <input type="text" className="form-control" value={currentDiet} onChange={handleChange(setCurrentDiet)} />
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.frontImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image1Input" ref={image1Input} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.backImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image2Input" ref={image2Input} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.sideImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image3Input" ref={image3Input} />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.save}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : <></>
            }

            {

                showAddPdfContainer
                    ?
                    <div className="shadow p-3 mb-5 bg-white rounded">
                        <div style={{ margin: 20 }}>
                            <form onSubmit={handlePdfSubmit}>
                                <div className="container">

                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoHeightInput}</label>
                                                <input type="number" className="form-control" value={height} onChange={handleChange(setHeight)} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label className="form-label">{resources.addUserMeasurementInfoWeightInput}</label>
                                                <input type="number" className="form-control" value={weight} onChange={handleChange(setWeight)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">Upload Pdf</label>
                                                <input type="file" accept="application/pdf" className="form-control" id="usermeasurementpdf" ref={filesInput} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.frontImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image1Input" ref={image1Input} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.backImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image2Input" ref={image2Input} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{resources.sideImage}</label>
                                                <input type="file" accept="image/jpeg, image/png, image/gif" className="form-control" id="image3Input" ref={image3Input} />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.save}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    : null
            }


            {
                currentRows.length > 0
                    ? <div className="shadow p-3 mb-5 bg-white rounded">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                <span style={{ marginRight: 10 }}>Count per page:</span>
                                <div className="btn-group me-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{resources.userDetailworkoutTableCreatedDate}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.userDetailworkoutTableFile}</th>
                                        <th style={{ textAlign: 'center' }}>Front Photo</th>
                                        <th style={{ textAlign: 'center' }}>Back Photo</th>
                                        <th style={{ textAlign: 'center' }}>Side Photo</th>
                                        <th style={{ textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows && currentRows.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.createdDateString}</td>
                                            {
                                                item.pdFpath == null || item.pdFpath == ""
                                                    ? <td></td>
                                                    : <td style={{ textAlign: 'center' }}><a className="fas fa-fw fa-file" style={{ cursor: 'pointer', marginRight: 5 }} href={item.pdFpath} target='_blank' /></td>
                                            }
                                            <td style={{ textAlign: 'center' }}>
                                                <a href={item.frontPhoto} target="_blank">
                                                    <img src={item.frontPhoto} style={{ height: 50, width: 50 }} />
                                                </a>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <a href={item.backPhoto} target="_blank">
                                                    <img src={item.backPhoto} style={{ height: 50, width: 50 }} />
                                                </a>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <a href={item.sidePhoto} target="_blank">
                                                    <img src={item.sidePhoto} style={{ height: 50, width: 50 }} />
                                                </a>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={() => handleButtonClick(item.id)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    {resources.delete}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                Previous
                            </button>
                            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                Next
                            </button>
                            <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                        </div>
                    </div>
                    : <h5>No data</h5>
            }
        </div>
    )
};

export default UserMeasurementInformation;