import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../store/auth-context';
import { login } from '../util/api';

const Login = () => {
    const authCtx = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);
        try {
            const response = await login(email, password, true);
            authCtx.login(response);
            setLoading(false);
            navigate("/home");
        } catch (error) {
            setLoading(false);
            setErrorMessage('An error occured because of server. Please try again!');
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src="/img/login-image.png" alt="login" style={{ width: 'inherit' }} />
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome</h1>
                                        </div>
                                        <form className="user" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input type="email" className="form-control form-control-user"
                                                    id="exampleInputEmail" aria-describedby="emailHelp"
                                                    placeholder="Enter Email Address..." value={email} onChange={(event) => setEmail(event.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-user"
                                                    id="exampleInputPassword" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} required />
                                            </div>
                                            <button className="btn btn-primary btn-user btn-block" disabled={loading}>
                                                {loading ? 'Loading' : 'Login'}
                                            </button>
                                            {
                                                errorMessage != ''
                                                    ? <span style={{ color: 'red' }}>{errorMessage}</span>
                                                    : null
                                            }
                                            <hr />
                                        </form>
                                        {/* <div className="text-center">
                                            <a className="small" href="forgot-password.html">Forgot Password?</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
