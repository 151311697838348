import React, { useEffect, useState, useContext } from "react";
import UserDetailContext from '../store/userdetail-context';
import LineChart from '../helpers/Charts/LineChart';
import BarChart from '../helpers/Charts/BarChart';
import { resources } from "../helpers/Resource";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";

const UserReport = ({ user }) => {

    return (
        <div style={{ marginTop: 20 }}>

            <LineChart data={{
                labels: user.weeklyChartDataLabel,
                values: user.weeklyChartData,
                dataTitle: resources.userDetailWeight,
                key: resources.userDetailWeight,
                beginAtZero: false,
                startValue: user.weeklyMin,
                suggestedMax: user.weeklyMax
            }} />

            <LineChart data={{
                labels: user.montlyChartDataLabel,
                values: user.montlyChartData,
                dataTitle: resources.userDetailWeight,
                key: resources.userDetailWeight,
                beginAtZero: false,
                startValue: user.monthlyMin,
                suggestedMax: user.monthlyMax
            }} />

            <LineChart data={{
                labels: user.dailyChartDataLabel,
                values: user.dailyChartData,
                dataTitle: resources.userDetailWeight,
                key: resources.userDetailWeight,
                beginAtZero: false,
                startValue: user.dailyMin,
                suggestedMax: user.dailyMax
            }} />
        </div>
    );
};

export default UserReport;