import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data }) => {
    const chartRef = useRef();
    const chartInstanceRef = useRef();

    useEffect(() => {
        const myChartRef = chartRef.current.getContext('2d');

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        chartInstanceRef.current = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: data.labels,
                datasets: [
                    {
                        label: data.dataTitle,
                        data: data.values,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: data.beginAtZero,
                        suggestedMin: data.startValue,
                        suggestedMax: data.suggestedMax
                    },
                },
            },
        });

        return () => {
            chartInstanceRef.current.destroy();
        };
    }, [data]);

    return (
        <div style={{ width: 700, height: 600, margin: '0 auto' }}>
            <canvas ref={chartRef} />
        </div>
    );
};

export default LineChart;