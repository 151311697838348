import React, { useEffect, useState } from 'react';
import { resources } from '../helpers/Resource';
import { saveOrUpdateUserBasicInfoApi } from '../util/api';
import Loading from './Loading';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

const UserBasicInfo = ({ user }) => {

    const [name, setName] = useState(user.name);
    const [surname, setSurname] = useState(user.surname);
    const [username, setUsername] = useState(user.username);
    const [email, setEmail] = useState(user.email);
    const [mobilenumber, setMobileNumber] = useState(user.mobileNumber);
    const [birthDayString, setBirthDayString] = useState(user.birthDayString);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(user.height);
    const [userProgress, setUserProgress] = useState(user.usersProgress);

    const nameChange = (event) => {
        setName(event.target.value);
    }

    const surnameChange = (event) => {
        setSurname(event.target.value);
    }

    const usernameChange = (event) => {
        setUsername(event.target.value);
    }

    const emailChange = (event) => {
        setEmail(event.target.value);
    }

    const mobilenumberChange = (event) => {
        setMobileNumber(event.target.value);
    }

    const birthdayStringChange = (event) => {
        setBirthDayString(event.target.value);
    }

    const heightChange = (event) => {
        setHeight(event.target.value);
    }

    const saveClick = async () => {
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('userid', user.id);
            formData.append('name', name);
            formData.append('surname', surname);
            formData.append('username', username);
            formData.append('email', email);
            formData.append('mobilenumber', mobilenumber);
            formData.append('birthDayString', birthDayString);
            formData.append('height', height);

            const response = await saveOrUpdateUserBasicInfoApi(formData);
            if (response.status === 200 && response.data == true) {
                setSuccess(true);
                setError(false);
                setLoading(false);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.errorMessage} />
    }

    return (
        <div style={{ marginTop: 20 }}>
            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.userDetailPageSuccessMessage} />
                    : null
            }
            <div className="shadow p-3 mb-5 bg-white rounded">

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50 }}>
                        <img src={user.profilePictureUrl} height={50} width={50} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, backgroundColor: '#F6DC00', borderRadius: 5, paddingRight: 25, paddingLeft: 25 }}>
                        <span style={{ fontSize: 18, color: 'black', fontWeight: '500' }}>{resources.userDetailPageUsersProgress} {userProgress} KG</span>
                    </div>
                </div>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{resources.userDetailUserBasicInfoTitle}</th>
                            <th>{resources.userDetailUserBasicInfoInfo}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoName}</td>
                            <td><input type="text" className="form-control" value={name} onChange={nameChange} /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoSurname}</td>
                            <td><input type="text" className="form-control" value={surname} onChange={surnameChange} /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoNameUsername}</td>
                            <td><input type="text" className="form-control" value={username} onChange={usernameChange} /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoNameEmail}</td>
                            <td><input type="text" className="form-control" value={email} onChange={emailChange} /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoNameMobileNumber}</td>
                            <td><input type="text" className="form-control" value={mobilenumber} onChange={mobilenumberChange} /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoNameBirthday}</td>
                            <td><input type="text" className="form-control" value={birthDayString} onChange={birthdayStringChange} placeholder='29/05/1990' /></td>
                        </tr>
                        <tr>
                            <td>{resources.userDetailUserBasicInfoHeight}</td>
                            <td><input type="text" className="form-control" value={height} onChange={heightChange} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'end' }}>
                                <button type="button" className="btn btn-primary" onClick={() => saveClick()}>{resources.save}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserBasicInfo;