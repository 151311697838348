import { useEffect, useRef, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { resources } from "../helpers/Resource";
import api_url, { deleteTrainerApi, getTrainersApi, saveTrainerApi } from "../util/api";

const Trainer = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fullName, setFullName] = useState('');
    const [title, setTitle] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [id, setId] = useState(0);
    const fileInput = useRef(null);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    const fullNameChange = (event) => {
        setFullName(event.target.value);
    }

    const titleChange = (event) => {
        setTitle(event.target.value);
    }

    useEffect(() => {
        getTrainers();
    }, []);

    const getTrainers = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await getTrainersApi();
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('fullName', fullName);
            formData.append('title', title);
            formData.append('image', fileInput.current.files[0]);
            formData.append('id', id);
            const response = await saveTrainerApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
                setFullName('');
                setTitle('');
                setData(response.data);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const deleteTrainer = async (id) => {
        setLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            formData.append('trainerId', id);
            const response = await deleteTrainerApi(formData);
            if (response.status === 200) {
                setLoading(false);
                setError(false);
                setData(response.data);
            }
            else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    const handleEditButton = (fullName, title, id) => {
        setFullName(fullName);
        setTitle(title);
        setId(id);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.trainerPageErrorMessage} />
    }

    return (
        <div style={{ margin: 20 }}>
            <h3>{resources.trainerPageCreateTrainer}</h3>
            <div className="shadow p-3 mb-5 bg-white rounded">
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">{resources.trainerPageFullName}</label>
                                    <input type="text" className="form-control" id="createLessonLessonTitle" value={fullName} onChange={fullNameChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">{resources.trainerPageTitle}</label>
                                    <input type="text" className="form-control" id="title" value={title} onChange={titleChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="input-group mb-3">
                                    <label className="input-group-text">Upload Avatar</label>
                                    <input type="file" className="form-control" id="createLessonFiles" ref={fileInput} accept="image/*" />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-primary btn-block" type="submit" disabled={loading || fullName == '' || title == ''}>{loading ? resources.loading : resources.trainerPageSaveButton}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {currentRows && currentRows.length > 0
                ? <div>

                    <div style={{ marginVertical: 20 }}>
                        <h3>{resources.trainerPageTrainers}</h3>
                        <div className="shadow p-3 mb-5 bg-white rounded">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>Count per page:</span>
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(10)}>10</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(25)}>25</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(50)}>50</button>
                                        <button type="button" className="btn btn-primary" onClick={() => setRowsPerPage(100)}>100</button>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }}>{resources.trainerPageThFullName}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.trainerPageThTitle}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.trainerPageThAvatar}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.trainerPageThEdit}</th>
                                        <th style={{ textAlign: 'center' }}>{resources.trainerPageThDelete}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows && currentRows.map((item) => (
                                        <tr key={item.id}>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.fullName}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.jobTitle}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <img style={{ height: 50, width: 50 }} src={item.avatarPath} alt={`Image ${item.id}`} />
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => handleEditButton(item.fullName, item.jobTitle, item.id)}>{resources.trainerPageEditButton}</button>
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteTrainer(item.id)}>{resources.trainerPageDeleteButton}</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className="btn btn-primary btn-sm">
                                    Previous
                                </button>
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / rowsPerPage)} className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>
                                    Next
                                </button>
                                <span style={{ marginLeft: 10 }}>{`Page ${currentPage} of ${Math.ceil(data.length / rowsPerPage)}`}</span>
                            </div>
                        </div>
                    </div>
                </div>

                : <div class="alert alert-warning" role="alert">
                    {resources.trainerPageNoData}
                </div>}
        </div>
    )
}

export default Trainer;