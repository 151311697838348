
const NotFound = () => {
    return (
        <div>
            <h3>Page Not Found</h3>
        </div>
    );
};

export default NotFound;
