


const Loading = () => {
    return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
        <div className="spinner-border" role="status">
        </div>
        <span className="visually-hidden">Loading...</span>

    </div>
}

export default Loading;