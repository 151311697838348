import { useEffect, useRef, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { resources } from "../helpers/Resource";
import api_url, { deleteTrainerApi, getTrainersApi, saveTrainerApi, sendPushNotifications } from "../util/api";
import SuccessMessage from "../components/SuccessMessage";

const NotificationScreen = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const titleChange = (event) => {
        setTitle(event.target.value);
    }

    const messageChange = (event) => {
        setMessage(event.target.value);
    }

    if (loading) {
        return <Loading />
    }

    if (!loading && error) {
        return <ErrorMessage errorMessage={resources.logsErrorMessage} />
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (title == null || title == '') {
            return;
        }

        if (message == null || message == '') {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('message', message);
            const response = await sendPushNotifications(formData);
            if (response.status === 200) {
                setLoading(false);
                setSuccess(true);
                setError(false);
            }
            else {
                setLoading(false);
                setSuccess(false);
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div style={{ margin: 20 }}>

            {
                !loading && success
                    ? <SuccessMessage successMessage={resources.userDetailPushNotiMessage} />
                    : null
            }


            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4>{resources.userPushNotiTitle}</h4>
            </div>

            <div className="shadow p-3 mb-5 bg-white rounded">
                <div style={{ margin: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="container">

                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.title}</label>
                                        <input type="text" className="form-control" value={title} onChange={titleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">{resources.message}</label>
                                        <input type="text" className="form-control" value={message} onChange={messageChange} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-primary btn-block" type="submit" disabled={loading}>{loading ? resources.loading : resources.send}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
};

export default NotificationScreen;